import React, { useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Grid, CircularProgress, Typography, Button, TextField, FormHelperText } from "@material-ui/core";
import axios from "axios";
import { Translate, loc } from "components";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    greeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    subGreetingSuccess: {
        fontWeight: 500,
        textAlign: "justify",
        marginTop: theme.spacing(2),
    },
    textFieldUnderline: {
        "&:before": {
            borderBottomColor: theme.palette.primary.light,
        },
        "&:after": {
            borderBottomColor: theme.palette.primary.main,
        },
        "&:hover:before": {
            borderBottomColor: `${theme.palette.primary.light} !important`,
        },
    },
    textField: {
        borderBottomColor: theme.palette.background.light,
    },
    createAccountButton: {
        height: 46,
        textTransform: "none",
        marginTop: 32
    },
});

const ResetPassword = (props) => {

    const { classes } = props;

    const history = useHistory();

    const [status, setStatus] = useState("NEW");
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        resetPassword();
    }

    const resetPassword = () => {
        if (passwordValue !== confirmPasswordValue) {
            setErrorMsg(loc("error.passwordsNotMatch"));
            return;
        }
        setIsLoading(true);
        axios
        .post("/reset-password/", { code: props.code, password: passwordValue })
        .then(() => {
            setIsLoading(false);
            setStatus("SUCCESS");
        }).catch(function(error) {
            setIsLoading(false);
            setStatus("FAILED");
            if (error.response && error.response.data && error.response.data.errorCode) {
                let errorCode = error.response.data.errorCode;
                switch (errorCode) {
                    case "auth/invalid-action-code":
                        setErrorMsg(loc("error.invalidActionCode"))
                        break;
                    default:
                        setErrorMsg(loc("error.anErrorOccurredWhileTryingToSendYourMessage"));
                        break;
                }
            } else {
                setErrorMsg(loc("error.anErrorOccurredWhileTryingToSendYourMessage"));
            }      
        });
    }

    const renderResetForm = () => {
        return (
            <Translate>
            {({ translate }) => <>
                <form className={classes.root} onSubmit={handleSubmit}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h3" className={classes.greeting}>
                                <Translate id="resetPassword.title" />
                            </Typography>
                        </Grid>
                        {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText> }
                        <TextField
                            id="password"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={passwordValue}
                            onChange={(e) => setPasswordValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("resetPassword.password")}
                            type="password"
                            fullWidth
                        />
                        <TextField
                            id="confirmPassword"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={confirmPasswordValue}
                            onChange={(e) => setConfirmPasswordValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("resetPassword.confirmPassword")}
                            type="password"
                            fullWidth
                            error={errorMsg.confirmPassword ? true : false}
                        />
                        {errorMsg && errorMsg.confirmPassword && <FormHelperText error>{errorMsg.confirmPassword}</FormHelperText>}
                        {isLoading ? (
                            <CircularProgress size={26} />
                        ) : (
                            <Button
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.createAccountButton}
                            >
                                <Translate id="resetPassword.saveBtn" />
                            </Button>
                        )}
                    </Grid>
                </form> 
            </>}
        </Translate>
        );
    }

    const renderSuccess = () => {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="h3" className={classes.greeting}>
                        <Translate id="resetPassword.successTitle" />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.subGreetingSuccess}>
                        <Translate id="resetPassword.successSubtitle" />
                    </Typography>
                </Grid>
                <Grid item>
                    <Button 
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.createAccountButton}
                        onClick={() => history.push("/login")}
                    >
                        <Translate id="resetPassword.backToLogin" />
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const renderFailed = () => {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="h3" className={classes.greeting}>
                        <Translate id="resetPassword.failedTitle" />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.subGreetingSuccess}>
                        <Translate id="resetPassword.failedSubtitle" />
                    </Typography>
                </Grid>
                <Grid item>
                    <Button 
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.createAccountButton}
                        onClick={() => history.push("/login/2")}
                    >
                        <Translate id="resetPassword.resetPassword" />
                    </Button>
                </Grid>
            </Grid>
        );
    }

    switch(status){
        case "NEW":
            return renderResetForm();
        case "SUCCESS": 
            return renderSuccess();
        case "FAILED": 
            return renderFailed();   
        default:
            return null;
    }
}

export default withRouter(withStyles(styles)(ResetPassword))