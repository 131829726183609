import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, Tabs, Tab } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Month, Week, Translate, CenterModal, loc } from "components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Select from "react-select";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles = (theme) => ({

    selectContainer: {
        paddingBottom: "2em",
        borderBottom: "1px solid #3B5441",
    },
    selectText: {
        fontSize: 17,
    },
    selectTextMobile: {
        fontSize: 16,
        textAlign: "center",
        marginBottom: 15
    },
    helpIcon: {
        cursor: "pointer",
        padding: 10
    },
    helpIconMobile: {
        cursor: "pointer",
    },
});

const MONTH = 0;
const WEEK = 1

const Calendar = (props) => {
    const { classes, facility, subFacilities } = props;

    const [date, setDate] = useState(moment(new Date()));
    const [showCalendar, setShowCalendar] = useState(WEEK);
    const [selectedSubFacility, setSelectedSubFacility] = useState(null);
    const [open, setOpen ] = React.useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    
    useEffect(() => {
        if (subFacilities.length !== 0) {
            var subFacility = subFacilities[0];
            setSelectedSubFacility(subFacility)
            if (subFacility.showTabs.showWeek) {
                setShowCalendar(WEEK)
            } else if (subFacility.showTabs.showMonth) {
                setShowCalendar(MONTH)
            }
        }
    }, [subFacilities]);

    const loadCalendar = () => {
        var calendar = null;
        if (showCalendar === MONTH) {
            calendar = (
                <Month date={date} facility={selectedSubFacility ? selectedSubFacility : facility} />
            );
        } else {
            if (!facility.minTime) {
                return;
            }
            calendar = selectedSubFacility && <Week date={date} facility={selectedSubFacility} />;
        }
        return calendar;
    };

    return (
        <Grid container>
            <Grid container style={{ height: "100%" }} alignItems="center" justify="center">
                {subFacilities.length>1 && <Grid container alignItems="center" justify="center" className={classes.selectContainer}>
                    <Grid item xs={12} md={7} className={isMobile ? classes.selectTextMobile : classes.selectText}>
                        <Translate id="calendar.reservationNextPerson" />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.inputContainer}>
                        <Select
                            value={selectedSubFacility}
                            options={subFacilities}
                            placeholder={<Translate id={"selectFacilityTypePlaceholder"} />}
                            isClearable={false}
                            onChange={(value) => setSelectedSubFacility(value)}
                        />
                    </Grid>
                </Grid>}
                <Grid item xs={12}>
                    <Grid container justify="center">
                        {isMobile && 
                            <Grid item onClick={() => setOpen(true)} className={classes.helpIconMobile}>
                                <HelpOutlineIcon />
                            </Grid>
                        }
                        {selectedSubFacility && selectedSubFacility.showTabs.show && <Tabs
                            value={showCalendar}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e, v) => setShowCalendar(v)}
                            aria-label="disabled tabs example"
                        >
                            {selectedSubFacility.showTabs.showMonth && <Tab label={loc("calendar.fullDayBooking")} />}
                            {selectedSubFacility.showTabs.showWeek && <Tab label={loc("calendar.anAppointmentBooking")} /> }
                        </Tabs>}
                        {!isMobile && 
                            <Grid item onClick={() => setOpen(true)} className={classes.helpIcon}>
                                <HelpOutlineIcon />
                            </Grid>
                        }
                        <CenterModal open={open} closeModal={() => setOpen(false)} title="Útmutató">
                            <Grid item xs={12} style={{ textAlign: "justify", fontSize: 16, marginTop: "1em" }}>
                                Ha a teljes nap foglalásra kattintasz, akkor a bejövő felületen teljes napra kibérelheted a fürdőt (pénteken és szombaton).
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "justify", fontSize: 16 }}>
                                Ha az időpontfoglalásra kattintasz, akkor a bejövő felületen egy időpontra foglalhatsz helyet-helyeket.
                            </Grid>
                        </CenterModal>
                    </Grid>
                    <Grid container justify="center" alignContent="center" style={{ marginTop: 20 }}>
                        {/* <Grid item onClick={() => setDate(date.clone().subtract(1, "years"))}>
                            {"<<"}
                        </Grid> */}
                        <Grid
                            item
                            onClick={() =>
                                showCalendar === 0 ? setDate(date.clone().subtract(1, "months")) : setDate(date.clone().subtract(1, "week"))
                            }
                            style={{ fontWeight: "bold", margin: 10, cursor: "pointer" }}
                        >
                            <ArrowBackIcon />
                        </Grid>
                        <Grid item style={{ fontWeight: "bold", margin: 10, fontSize: 19 }}>
                            {date.format("YYYY MMMM")}
                        </Grid>
                        <Grid
                            item
                            style={{ fontWeight: "bold", margin: 10, cursor: "pointer" }}
                            onClick={() => (showCalendar === 0 ? setDate(date.clone().add(1, "months")) : setDate(date.clone().add(1, "week")))}
                        >
                            <ArrowForwardIcon />
                        </Grid>
                        {/* <Grid item onClick={() => setDate(date.clone().add(1, "years"))}>
                            {">>"}
                        </Grid> */}
                    </Grid>
                    {loadCalendar()}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(Calendar);
