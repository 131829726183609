import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon  from '@material-ui/icons/LocalPhone'; 
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = (theme) => ({
    contactIcon: {
        zIndex: 10,
        paddingTop: 7,
        paddingRight: 3
    },
    contactInfo: {
        boxShadow: "-35px 0px 0px 0px #81c784",
        border: "3px solid #81c784",       
        borderRadius: 14,
        padding: "5px 10px",
        cursor: "pointer",
        textDecoration: "none",
    },
    contactInfoTxt: {
        fontWeight: "bold",
    }
});

export const formatPhoneNumber = (value) => {
    if(value){
        var phoneNumber = value;
        if (!phoneNumber.includes('+')) {
            phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
            value = phoneNumber.substring(0, 4) + " " + phoneNumber.substring(4, 7) + " " + phoneNumber.substring(7, 10) + " " + phoneNumber.substring(10, phoneNumber.length);
        } else {
            phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
            value = "+" + phoneNumber.substring(0, 2) + " " + phoneNumber.substring(2, 5) + " " + phoneNumber.substring(5, 8) + " " + phoneNumber.substring(8, 11) + " " + phoneNumber.substring(11, phoneNumber.length);
        } 
    }
    return (value);
}

const Contact = (props) => {
    const { classes, facility } = props;

    const isMobile = useMediaQuery('(max-width:600px)');

    const contactPhone = facility?.contactPhone;
    const contactEmail = facility?.contactEmail;
    

    if (!contactPhone && !contactEmail) {
        return null;
    }

    return (
        <Grid item xs={12} className={classes.contact} style={{padding: 20}}>
            <Grid container justify="center" spacing={2}>
                {contactPhone && 
                    <Grid item xs={12} md={6} >
                        <Grid container direction="row" justify="center">
                            <Grid item className={classes.contactIcon}>
                                <LocalPhoneIcon />
                            </Grid>
                            <Link href={"tel:" + contactPhone} style={{textDecoration: "none", minWidth: isMobile ? "75%" : "40%"}}>
                                <Grid item className={classes.contactInfo}>
                                    <Typography variant="body1" component="h2" style={{fontWeight: "bold", textAlign: "center"}}>
                                        {formatPhoneNumber(contactPhone)}
                                    </Typography>
                                </Grid>
                            </Link>
                        </Grid>
                    </Grid>
                }
                {contactEmail && 
                    <Grid item xs={12} md={6}>
                        <Grid container direction="row" justify="center">
                            <Grid item className={classes.contactIcon}>
                                <EmailIcon />
                            </Grid>
                            <Link href={"mailto:" + contactEmail} style={{textDecoration: "none", minWidth: isMobile ? "75%" : "50%"}}>
                                <Grid item className={classes.contactInfo}>
                                    <Typography variant="body1" component="h2" style={{fontWeight: "bold", textAlign: "center"}}>
                                        {contactEmail}
                                    </Typography>
                                </Grid>
                            </Link>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

export default withRouter(withStyles(styles)(Contact));