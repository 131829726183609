import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, AppBar, Toolbar, Typography, MenuItem, Menu, IconButton, Button, Hidden } from "@material-ui/core/";
import AccountCircle from "@material-ui/icons/AccountCircle";
import axios from "axios";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Loc, LanguageToggle } from "components";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#3B5441",
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
        //fontSize: "4.3vw",
    },
    partnerBtn: {
        marginLeft: theme.spacing(3),
    },
    languageToggle: {
        maxWidth: "4.8em"
    },
    languageToggleMobile: {
        maxWidth: "4.7em"
    }
}));

const Header = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, user } = useSelector((state) => state.user);
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOut = () => {
        axios.post("/signOut").then((res) => {
            console.log("sikeres kijelentekezez");
            Cookies.remove("token");
            Cookies.remove("dataToken");
            dispatch({ type: "SIGN_OUT" });
        });
    };

    const classes = useStyles();

    const headerMdLgXl = () => {
        return (
            <AppBar className={classes.appBar} position="static">
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} lg={10} xl={8}>
                        <Toolbar style={{ minHeight: 40 }}>
                            {/* <Button color="inherit" onClick={() => history.push("/")}> */}
                            <Button color="inherit" onClick={() => history.push("/")}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.title}>
                                            {/* FOGLALÓ<span style={{ color: "#FFF" }}>.RO</span> */}
                                            IDŐPONTFOGLALÓ
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Button>
                            <Typography variant="h6" className={classes.title}></Typography>

                            {isAuthenticated ? (
                                <>
                                    <Button onClick={() => history.push("/searchFacility")} className={classes.menuButton} color="inherit">
                                       <Loc value="menu.facilities" />
                                    </Button>
                                    <Button onClick={() => history.push("/search-place")} className={classes.menuButton} color="inherit">
                                        <Loc value="menu.places" />
                                    </Button>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={open}
                                        onClose={handleClose}
                                        style={{ marginTop: 20, marginLeft: -15 }}
                                    >
                                        <MenuItem onClick={() => history.push("/profile")}>
                                            <Loc value="menu.profile" />
                                        </MenuItem>
                                        {user.business && 
                                            <MenuItem onClick={() => history.push("/appointments")}>
                                                 <Loc value="menu.appointments" />
                                            </MenuItem>
                                        }
                                        <MenuItem onClick={signOut}>
                                            <Loc value="menu.logout" />
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => history.push("/login")} color="inherit" >
                                        <Loc value="menu.login" />
                                    </Button>
                                    <Button onClick={() => history.push("/login/1")} color="inherit" >
                                        <Loc value="menu.signup" />
                                    </Button>
                                </>
                            )}
                            <Grid item xs={1} className={classes.languageToggle}>
                                <LanguageToggle />
                            </Grid>
                        </Toolbar>
                    </Grid>
                </Grid>
            </AppBar>
        );
    };

    const headerXsSm = () => {
        return (
            <AppBar className={classes.appBar} position="static">
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} lg={10} xl={8}>
                        <Toolbar style={{ minHeight: 40 }}>
                            {/* <Button color="inherit" onClick={() => history.push("/")}> */}
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <Grid container justify="center" alignItems="center">
                                        {isAuthenticated && 
                                            <>
                                                <IconButton
                                                    aria-label="account of current user"
                                                    aria-controls="menu-appbar"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                    color="inherit"
                                                >
                                                    <MenuIcon />
                                                </IconButton>
                                                <Menu
                                                    id="menu-appbar"
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                    open={open}
                                                    onClose={handleClose}
                                                    style={{ marginTop: 20, marginLeft: -15 }}
                                                >
                                                    <MenuItem disabled onClick={handleClose}>
                                                        Profil
                                                    </MenuItem>
                                                    {user.business && <MenuItem onClick={() => history.push("/appointments")}>Foglalások</MenuItem>}
                                                    <MenuItem onClick={() => history.push("/searchFacility")}>Szolgáltatások</MenuItem>
                                                    <MenuItem onClick={() => history.push("/search-place")}>Helységek</MenuItem>
                                                    <MenuItem onClick={signOut}>Kijelentkezés</MenuItem>
                                                </Menu>
                                            </>
                                        }
                                        <Button color="inherit" onClick={() => history.push("/")}>
                                            <Typography variant="h6" className={classes.title}>
                                                IDŐPONTFOGLALÓ
                                            </Typography>
                                        </Button>
                                        {isAuthenticated && <Grid item xs={3} className={classes.languageToggleMobile}>
                                            <LanguageToggle />
                                        </Grid>}
                                        
                                    </Grid>
                                </Grid>
                                {!isAuthenticated && <Grid item xs={4} className={classes.languageToggleMobile}>
                                    <LanguageToggle />
                                </Grid>}
                                <Grid item xs={8}>
                                    <Grid container justify="flex-end">
                                        {!isAuthenticated &&
                                            <>
                                                <Button onClick={() => history.push("/login")} color="inherit" >
                                                    <Typography style={{ fontSize: "0.8em" }}>
                                                        <Loc value="menu.login" />
                                                    </Typography>
                                                </Button>
                                                <Button onClick={() => history.push("/login/1")} color="inherit" >
                                                    <Typography style={{ fontSize: "0.8em" }}>
                                                        <Loc value="menu.signup" />
                                                    </Typography>
                                                </Button>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                    
                            {/* <Typography variant="h6" className={classes.title}></Typography> */}

                        </Toolbar>
                    </Grid>
                </Grid>
            </AppBar>
        );
    };

    return (
        <>
            <Hidden smDown>{headerMdLgXl()}</Hidden>
            <Hidden mdUp>{headerXsSm()}</Hidden>
        </>
    );
};

export default Header;
