import React, { useState, useEffect } from "react";
import { Grid, Drawer } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Header, FacilityCard, AddCard } from "components";
import { useSelector } from "react-redux";
import { AddFacilityModal } from "components";

const styles = (theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    searchContainer: {
        padding: "3% 10px",
        //backgroundColor: "#eaeaea",
    },
    inputContainer: {
        padding: "1%",
    },
    media: {
        height: 30,
        paddingTop: "56.25%", // 16:9
        width: "100%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
});

const AdminSearchFacility = (props) => {
    const { user } = useSelector((state) => state.user);
    const [filteredFacilities, setFilteredFacilities] = useState([]);
    const [openModal, setOpenModal] = useState(true);

    useEffect(() => {
        axios.get("/facilitiesByUserId/" + user.id).then((facilities) => {
            setFilteredFacilities(facilities.data);
        });
        // axios.get("/constants/location_type").then((locationTypes) => {
        //     const types = locationTypes.data.map((type) => ({ value: type, label: <Translate id={type} /> }));
        //     setLocationTypes(types);
        // });
        // axios.get("/constants/facility_type").then((facilityTypes) => {
        //     const types = facilityTypes.data.map((type) => ({ value: type, label: <Translate id={type} /> }));
        //     setFacilityTypes(types);
        // });
    }, [user]);

    // const editFacility = (facility) => {
    //     setOpenModal(true);
    //     setPropsFacility(facility);
    // };

    const closeModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Header />
            <Grid container justify="center">
                <Grid item xs={12} lg={10} xl={8}>
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} style={{ margin: "20px 0px" }}>
                                    <Grid container justify="center" alignItems="center" style={{ minHeight: "100%" }}>
                                        <Grid item xs={12}>
                                            <AddCard add={() => setOpenModal(true)} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {filteredFacilities.map((facility) => (
                                    <Grid item key={facility.id} xs={12} sm={6} md={6} lg={4} xl={3} style={{ margin: "20px 0px" }}>
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item xs={11}>
                                                <FacilityCard facility={facility} /*handleClick={() => editFacility(facility)} */ />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Drawer anchor={"right"} open={openModal} onClose={closeModal}>
                        <AddFacilityModal closeModal={closeModal} />
                    </Drawer>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(withStyles(styles)(AdminSearchFacility));
