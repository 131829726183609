import Cookies from "js-cookie";
import decode from "jwt-decode";
import axios from "axios";

export const SET_LANGUAGE = 'login/SET_LANGUAGE';

const isBrowser = typeof window !== "undefined";
const inicialState = {
    user: isBrowser && Cookies.get("dataToken") ? decode(Cookies.get("dataToken")).user : {},
    isAuthenticated: isBrowser && Cookies.get("token"),
    currentForm: "signIn",
};

export default (state = inicialState, action) => {
    switch (action.type) {
        case "SIGN_IN_SUCCESS":
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                user: action.payload.user,
            };
        case "SIGN_OUT":
            return {
                ...state,
                isAuthenticated: false,
                user: {},
            };
        case "SIGNUP":
            return {
                ...state,
                currentForm: "signUp",
            };
        case "CHECK_TOKEN":
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                user: action.payload.user,
            };
        case SET_LANGUAGE: {
            return {
                ...state,
                user: {...state.user, language: action.payload }
            };
        }
        default:
            return state;
    }
};

export const setLanguage = (userId, languageCode) => dispatch => {
    // update language field in user
    axios.post("/user/language", {userId: userId, language: languageCode});

    dispatch({
        type: 'SET_LANGUAGE',
        payload: languageCode
    })
}


