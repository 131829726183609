import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Header, FacilityCard, Translate } from "components";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = () => ({
    title: {
        fontWeight: "bold",
        fontSize: 44,
        color: "#3B5441",
        textAlign: "center",
        marginTop: "4%",
        marginBottom: "4%",
    },
    mobileTitle: {
        fontWeight: "bold",
        fontSize: 20,
        color: "#3B5441",
        textAlign: "center",
        marginTop: "4%",
        marginBottom: "4%",
    }
});

const SearchFacility = (props) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    
    const [filteredFacilities, setFilteredFacilities] = useState([]);

    useEffect(() => {
        axios.get("/facilities").then((facilities) => {
            setFilteredFacilities(facilities.data);
        });
    }, []);

    const { classes } = props;

    return (
        <>
            <Header />
            <Grid container justify="center">
                <Grid item xs={12} className={isMobile ? classes.mobileTitle : classes.title}>
                    <Translate id="searchFacility.title" />
                </Grid>
                <Grid item xs={12} lg={10} xl={8}>
                    <Grid container justify="center">
                        {filteredFacilities.map((facility) => (
                            <Grid item key={facility.id} xs={12} sm={6} md={6} lg={4} xl={3} style={{ margin: "20px 0px" }}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={11}>
                                        <FacilityCard facility={facility} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(withStyles(styles)(SearchFacility));
