import React from "react";
import { withLocalize } from "react-localize-redux";
import Select from "react-select";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { setLanguage } from "reducers/userReducer";
import { useDispatch } from "react-redux";

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#3B5441', border: "none", boxShadow: 'none', fontFamily: 'Roboto', fontSize: 16 }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "rgb(129, 199, 132)" : "#FFF",
            color: isFocused ? "#FFF" : "#3B5441",
            ':active': {
                ...styles[':active'],
                backgroundColor: "rgb(129, 199, 132)"
            },
        };
    },
    dropdownIndicator: base => ({
        ...base,
        color: "white" // Custom colour
    }),
    singleValue: (styles, { data }) => ({ ...styles, color: "white" }),
};

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => {

    const dispatch = useDispatch()

    const { isAuthenticated, user } = useSelector((state) => state.user);

    const handleOnChange = (lang) => {
        setActiveLanguage(lang.value);
        Cookies.set("language", lang.value, { expires: 7 });
        if (isAuthenticated) {
            dispatch(setLanguage(user.id, lang.value));
        }
    }

    return (
        <Select
            value={{value: activeLanguage.code, label: activeLanguage.name}}
            options={languages.map(language => { return {value: language.code, label: language.name}; })}
            isClearable={false}
            onChange={handleOnChange}
            components={{
                IndicatorSeparator: () => null
            }}
            styles={colourStyles}
        />
    )
};

export default withLocalize(LanguageToggle);