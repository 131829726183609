import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography, Avatar } from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom";
import { Loc } from "components";

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        margin: "auto",
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
        width: "100%",
        backgroundColor: "#000",
    },
    avatar: {
        height: 60,
        width: 60,
    },
    cardSubTitle: {},
});

const PlaceCard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { place, handleClick } = props;
    const { location, avatar, backgroundPhoto, slug } = place;
    return (
        <Card className={classes.root} onClick={handleClick ? handleClick : () => history.push("/place/" + slug)}>
            <CardActionArea>
                <CardMedia className={classes.media} image={backgroundPhoto} title="Contemplative Reptile" />
                <CardContent>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3} xl={3}>
                            <Avatar aria-label="recipe" className={classes.avatar} src={avatar}>
                                HK
                            </Avatar>
                        </Grid>
                        <Grid item xs={9} xl={9}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography noWrap={true} variant="h6" component="h2" style={{ fontSize: "1rem" }}>
                                        <Loc value={"place." + slug} />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary" component="p" className={classes.cardSubTitle}>
                                        {location}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default withRouter(PlaceCard);
