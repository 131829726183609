import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Paper, Modal } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';


const styles = (theme) => ({
    modalContainer: {
        position: "absolute",
        width: "500px",
        maxWidth: "90%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    closeIconContainer: {
        padding: 10,
        cursor: "pointer",
        position: "absolute",
        top: 0,
        right: 0
    },
    title: {
        fontSize: 22,
        fontWeight: "bold"
    }
});

const CenterModal = (props) => {
    const { classes, closeModal, open, title } = props;

    return (
        <Modal open={open} onClose={closeModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <Paper>
                <Grid container className={classes.modalContainer}>
                    <Grid item xs={12}>
                        <Grid container justify="center">
                            <Grid item className={classes.title}>
                                {title}
                            </Grid>
                            <Grid item className={classes.closeIconContainer} onClick={closeModal}>
                                <CloseIcon />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default withStyles(styles)(CenterModal);
