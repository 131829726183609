import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { LocalizeProvider } from "react-localize-redux";
import configureStore from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { Local } from "components";

ReactDOM.render(
    <Provider store={configureStore()}>
        <BrowserRouter>
            <LocalizeProvider store={configureStore()}>
                <Local />
                <App />
            </LocalizeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
