import React from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({});

const CustomTimePicker = (props) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <TimePicker
                label={props.label ? props.label : "Time Picker"}
                value={props.value ? moment(props.value) : moment("00:00", "HH:mm")}
                ampm={false}
                autoOk={false}
                minutesStep={props.minutesStep ? props.minutesStep : 60}
                onChange={(time) => props.setSelectedTime(time)}
                error={props.errorMessage ? true : false}
                disabled={props.disabled}
            />

            {/* {props.errorMessage && (
                    <Grid item xs={12}>
                        {props.errorMessage}
                    </Grid>
                )} */}
        </MuiPickersUtilsProvider>
    );
};

export default withStyles(styles)(CustomTimePicker);
