import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

export const useAppointments = (facility, startTime, isWeek, refresh) => {
    const [appointments, setAppointments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAppointments = async () => {
            if (facility && facility.id && startTime) {
                var time;
                if (isWeek) {
                    time = `/${startTime.startOf("week").format("YYYY-MM-DD")}T00:00:00.000Z`;
                } else {
                    const newStartTime = startTime.clone();
                    time = `/${newStartTime.subtract(1, 'days').format("YYYY-MM-DD")}T00:00:00.000Z`;
                }
                await axios.get("/appointments/" + facility.id + time).then(async (res) => {
                    var appointments = res.data.map((app) => {
                            // convert string dates to moment dates
                            var newApp = app;
                            newApp.startTime = moment(app.startTime);
                            newApp.endTime = moment(app.endTime);
                            newApp.diffTime = moment(app.diffTime);
                            newApp.paidDate = moment(app.paidDate);
                            return app;
                        });
                    setIsLoading(false);
                    setAppointments(appointments);               
                });
            }
        };
        fetchAppointments();
    }, [facility, startTime, refresh, isWeek]);

    return [appointments, isLoading];
};
