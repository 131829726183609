import React from "react";
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText, Button } from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom";
import { Translate } from "components";

const LoginDialog = (props) => {
    const history = useHistory();
    const { openDialog, setOpenDialog } = props;
    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Translate id="loginDialog.appointmentCondition" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Translate id="loginDialog.pleaseLogin" />
                </DialogContentText>
            </DialogContent>
            <Grid container>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        onClick={() => {
                            history.push("/login");
                            setOpenDialog(false);
                        }}
                        color="primary"
                    >
                        <Translate id="loginDialog.login" />
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        onClick={() => {
                            history.push("/login/1");
                            setOpenDialog(false);
                        }}
                        color="primary"
                    >
                        <Translate id="loginDialog.signUp" />
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default withRouter(LoginDialog);
