import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Header, PlaceCard } from "components";
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    searchContainer: {
        padding: "3% 10px",
        //backgroundColor: "#eaeaea",
    },
    inputContainer: {
        padding: "1%",
    },
    title: {
        fontWeight: "bold",
        fontSize: 44,
        color: "#3B5441",
        textAlign: "center",
        marginTop: "4%",
        marginBottom: "4%",
    },
});

const SearchPlace = (props) => {
    const [places, setPlaces] = useState([]);

    useEffect(() => {
        axios.get("/places").then((places) => {
            setPlaces(places.data);
        });
    }, []);

    const { classes } = props;

    return (
        <>
            <Header />
            <Grid container justify="center">
                <Grid item xs={12} className={classes.title}>
                    <Translate id="searchPlace.places" />
                </Grid>
                <Grid item xs={12} lg={10} xl={8}>
                    <Grid container justify="center">
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                {places.map((place) => (
                                    <Grid item key={place.id} xs={12} sm={6} md={6} lg={4} xl={3} style={{ margin: "20px 0px" }}>
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item xs={11}>
                                                <PlaceCard place={place} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(withStyles(styles)(SearchPlace));
