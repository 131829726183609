import React, { useState } from "react";
import { Grid, CircularProgress, Typography, Button, TextField, FormHelperText } from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom";
import axios from "axios";
import { Translate, loc } from "components";
import withStyles from "@material-ui/core/styles/withStyles";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";

const styles = theme => ({
    greeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    errorMessage: {
        textAlign: "center",
    },
    successMessage: {
        textAlign: "center",
        color: "green",
    },
    textFieldUnderline: {
        "&:before": {
            borderBottomColor: theme.palette.primary.light,
        },
        "&:after": {
            borderBottomColor: theme.palette.primary.main,
        },
        "&:hover:before": {
            borderBottomColor: `${theme.palette.primary.light} !important`,
        },
    },
    textField: {
        borderBottomColor: theme.palette.background.light,
    },
    createAccountButton: {
        height: 46,
        textTransform: "none",
        marginTop: 32
    },
});

const Register = (props) => {

    const { classes } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState({ email: "", password: "" });
    const [nameValue, setNameValue] = useState("");
    const [loginValue, setLoginValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
    const [phoneValue, setPhoneValue] = useState("");

    const signUp = () => {
        if (passwordValue !== confirmPasswordValue) {
            setErrorMsg({ confirmPassword: loc("error.login.passwordsEquality") });
            return;
        }
        setIsLoading(true);
        axios
            .post("/signup/", {
                email: loginValue,
                password: passwordValue,
                confirmPassword: confirmPasswordValue,
                business: false,
                phone: phoneValue,
                name: nameValue,
            })
            .then((res) => {
                Cookies.set("token", res.data.token, { expires: 7 });
                Cookies.set("dataToken", res.data.dataToken, { expires: 7 });
                history.push("/facility/sosfurdo");
                dispatch({ type: "SIGN_IN_SUCCESS", payload: { isAuthenticated: true, user: decode(res.data.dataToken).user } });
                setIsLoading(false);
                setErrorMsg(null);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errorCode) {
                    let errorCode = error.response.data.errorCode;
                    switch (errorCode) {
                        case "auth/email-already-in-use":
                            setErrorMsg({ email: loc("error.signup.emailAlreadyInUse")})
                            break;
                        default:
                            setErrorMsg({ email: loc("error.login.signupError") });
                            break;
                    }
                } else {
                    setErrorMsg({ email: loc("error.login.signupError") });
                }      
                setIsLoading(false);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        signUp();
    }

    return (
        <Translate>
            {({ translate }) => <>
                <form className={classes.root} onSubmit={handleSubmit}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h1" className={classes.greeting}>
                                <Translate id="login.signupWelcome" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.subGreeting}>
                                {loc("login.signupSubtitle")}
                            </Typography>
                        </Grid>
                        <TextField
                            id="email"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={loginValue}
                            onChange={(e) => setLoginValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("login.email")}
                            type="email"
                            fullWidth
                            error={errorMsg.email ? true : false}
                        />
                        {errorMsg && errorMsg.email && <FormHelperText error>{errorMsg.email}</FormHelperText> }
                        <TextField
                            id="password"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={passwordValue}
                            onChange={(e) => setPasswordValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("login.password")}
                            type="password"
                            fullWidth
                        />
                        <TextField
                            id="confirmPassword"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={confirmPasswordValue}
                            onChange={(e) => setConfirmPasswordValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("login.confirmPassword")}
                            type="password"
                            fullWidth
                            error={errorMsg.confirmPassword ? true : false}
                        />
                        {errorMsg && errorMsg.confirmPassword && <FormHelperText error>{errorMsg.confirmPassword}</FormHelperText>}
                        <TextField
                            id="name"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={nameValue}
                            onChange={(e) => setNameValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("login.name")}
                            fullWidth
                        />
                        <TextField
                            id="phone"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={phoneValue}
                            onChange={(e) => setPhoneValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("login.phoneNr")}
                            type="tel"
                            fullWidth
                        />
                        {isLoading ? (
                            <CircularProgress size={26} />
                        ) : (
                            <Button
                                disabled={
                                    loginValue.length === 0 ||
                                    passwordValue.length === 0 ||
                                    confirmPasswordValue.length === 0 ||
                                    nameValue.length === 0 ||
                                    phoneValue.length === 0
                                }
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.createAccountButton}
                            >
                                <Translate id="login.signupBtn" />
                            </Button>
                        )}
                        {/* <Grid item>
                            <Typography className={classes.formDividerWord}>vagy</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ minWidth: "100%" }}>
                            <FacebookAuth textButton="Sign up with Facebook" btnStyle={classes.facebookBtn} />
                        </Grid>
                        <GoogleAuth textButton="Sign up with Google" btnStyle={classes.facebookBtn} /> */}
                    </Grid>
                </form> 
            </>}
        </Translate>
    );
};

export default withRouter(withStyles(styles)(Register));
