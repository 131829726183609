import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/hu";
import "moment/locale/de";
import { Grid, GridList, GridListTile } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useSelector } from "react-redux";
import { LoginDialog, AddWeekAppointment } from "components";
import { useAppointments } from "actions";

const styles = (theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: "red",
    },
    gridList: {
        position: "relative",
        padding: 0,
        margin: 0,
        "& ul": {
            margin: 0,
        },
    },
    appointmentBox: {
        position: "absolute",
        width: "14%",
        border: "1px solid black",
    },
});

// //TODO kulombozo idopontok lehetenek (hetvegen rovidebb program, stb)
// const startTime = moment("08:00", "HH:mm");
// const endTime = moment("18:00", "HH:mm");
// const minTime = 60;

const FREE = 0;
const BUSY = 1;
const USER = 2;
const ADMIN = 3;

const getActualMonthDates = (date) => {
    var startOfMonth = moment(date).startOf("week");
    startOfMonth = startOfMonth.subtract(startOfMonth.weekday(), "days");
    var newDates = [...Array(7)].map((e, i) => moment(startOfMonth).add(i, "days"));
    return newDates;
};

const Week = (props) => {
    const { classes, date, facility } = props;

    const { isAuthenticated, user } = useSelector((state) => state.user);

    const [openAdd, setOpenAdd] = React.useState(false);
    const [dates, setDates] = useState([]);
    const [times, setTimes] = useState([]);
    const [dateTimes, setDateTimes] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dateNr, setDateNr] = useState(moment());
    const [appointment, setAppointment] = useState(null);
    const [freePlaces, setFreePlaces] = useState(facility.places);
    const [refreshApps, setRefreshApps] = useState(0);
    const [appointments] = useAppointments(facility, date, true, refreshApps);

    useEffect(() => {
        var newDates = getActualMonthDates(date);
        var newTimes;
        if (user.business && facility.adminStartTime && facility.adminEndTime) {
            newTimes = getTimes(facility.adminStartTime, facility.adminEndTime, facility.minTime);
        } else {
            newTimes = getTimes(facility.startTime, facility.endTime, facility.minTime);
        }
        var newDateTimes = [];
        newTimes.forEach((time) => {
            newDates.forEach((date) => {
                var dateTime = moment(date.format("YYYY-MM-DD ") + time.format("HH:mm"));
                newDateTimes = [...newDateTimes, { dateTime, places: facility.places, appointment: null }];
            });
        });
        var openDay;
        if (user.business && facility.adminStartTime) {
            openDay = facility.endTime.diff(facility.adminStartTime, "minutes")
        } else {
            openDay = facility.endTime.diff(facility.startTime, "minutes")
        }
        
        // beazonositom, hogy melyik appoitment melyik cellahoz tartozik
        appointments.forEach((appointment) => {
            var timeDiff = appointment.startTime.diff(newDateTimes[0].dateTime, "minutes");
            if (timeDiff >= 7 * 24 * 60 || appointment.isDayAppointment) return;
            var i = -1;
            if (timeDiff < openDay) {
                i = (timeDiff / facility.minTime) * 7;
            } else {
                // ha vasarnap akkor nem jo ez az if nelkul
                if (appointment.startTime.day() === 0) {
                    //TODO nem tudom mi van vele, kozbe elromlik a vasarnap, lehet az oraatalitassal kapcsolatos a baj
                    // i = Math.floor(timeDiff / (60 * 24)) + ((timeDiff % (60 * 24)) / facility.minTime - 1) * 7;
                    i = Math.floor(timeDiff / (60 * 24)) + ((timeDiff % (60 * 24)) / facility.minTime ) * 7;
                } else {
                    i = Math.floor(timeDiff / (60 * 24)) + ((timeDiff % (60 * 24)) / facility.minTime ) * 7;
                }
            }
            var duration = appointment.duration;
            while (duration > 1) {
                if (newDateTimes[i]) {
                    newDateTimes[i].places -= appointment.places ? appointment.places : 1;
                    if (user.id && user.id === appointment.userId) {
                        newDateTimes[i].appointment = appointment;
                    }
                }

                duration = duration - facility.minTime;
                i += 7;
            }
        });
        setDateTimes(newDateTimes);
        setDates(newDates);
        setTimes(newTimes);
    }, [props, appointments, user, facility, date]);

    const getTimes = (startTime, endTime, minTime) => {
        var timesNr = endTime.diff(startTime, "minutes") / minTime;
        var newDates = [...Array(timesNr)].map((e, i) => {
            return moment(startTime).add(minTime * i, "minutes");
        });
        return newDates;
    };

    // const getTimeNr = (appointmentStartTime) => {
    //     var x = appointmentStartTime.diff(startTime, "minutes") / minTime;
    //     x = x % 24;
    //     if (x < 0) {
    //         x = 24 + x;
    //     }
    //     x = x % 24;
    //     return x;
    // };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const createAppointment = (i, freePlaces) => {
        if (!isAuthenticated) {
            setOpenDialog(true);
            return;
        }
        const date = dates[i % 7].format("YYYY-MM-DD");
        const time = times[(i - (i % 7)) / 7].format("HH:mm");
        const newDate = moment(date + " " + time);
        setDateNr(newDate);
        setAppointment(undefined);
        setOpenAdd(true);
        setFreePlaces(freePlaces);
    };

    const editAppointment = (app) => {
        if (!isAuthenticated) {
            setOpenDialog(true);
            return;
        }
        setDateNr(app.startTime);
        setAppointment(app);
        setOpenAdd(true);
    };

    const appointmentStatus = (cell) => {
        var formatDate = cell.dateTime.format("HH:mm");
        var weekday = cell.dateTime.weekday();
        // if (!cell) return FREE;
        if (cell.appointment) {
            return USER;

        }
        //TODO kiveteles nyiltnapok
        // if (cell.dateTime.format("YYYY-MM-DD") === "2021-05-10") {
        //     //TODO tedd vissza
        //     return FREE;
        // }
        //TODO kiveteles zart napok
        if (facility.openZone) {
            var temp = facility.openZone.filter(zone => zone.startTime <= formatDate && zone.endTime > formatDate && weekday === zone.weekday && cell.places !== 0 );
            if (temp.length !== 0) {
                return temp[0].admin ? ADMIN : FREE;
            }
        } else {
            return cell.places !== 0 ? FREE : BUSY;
        }
        return BUSY;
    };

    const calendarColor = (status) => {
        switch (status) {
            case FREE:
                return "#81c784";
            case BUSY:
                return "rgba(0, 0, 0, 0.12)";
            case USER:
                return "#2196f3";
            case ADMIN:
                return "#81c784";
            default:
                return "#81c784";
        }
    };

    return (
        <Grid container>
            {openAdd && (
                <AddWeekAppointment
                    open={openAdd}
                    dateNr={dateNr}
                    appointment={appointment}
                    facility={facility}
                    closeModal={() => {
                        setOpenAdd(false);
                        setRefreshApps(refreshApps + 1);
                    }}
                    appointments={appointments}
                    freePlaces={freePlaces}
                    dateTimes={dateTimes}
                />
            )}
            <Grid item xs={2} lg={1}>
                {/* <Grid container style={{ height: "100%", border: "1px #DDD solid" }}></Grid> */}
            </Grid>
            <Grid item xs={10} lg={11} style={{ marginBottom: 2 }}>
                <GridList cellHeight={40} cols={7} className={classes.gridList}>
                    {dates.map((e, i) => {
                        e.locale('hu')
                        return (
                            <GridListTile key={i} cols={1} style={{ border: "1px #DDD solid" }}>
                                <Grid container style={{ height: "100%" }} justify="center" alignItems="center">
                                    <Grid item>{e.format("DD") + " " + capitalize(e.format("ddd"))}</Grid>
                                </Grid>
                            </GridListTile>
                        );
                    })}
                </GridList>
            </Grid>
            <Grid item xs={2} lg={1} style={{ marginRight: 2, marginLeft: -2 }}>
                <GridList cellHeight={30} cols={1} className={classes.gridList}>
                    {times.map((e, i) => {
                        return (
                            <GridListTile key={i} cols={1} style={{ border: "1px #DDD solid" }}>
                                <Grid container style={{ height: "100%" }} justify="center" alignItems="center">
                                    {/* <Grid item>{e.format("HH:mm")}</Grid> */}
                                    <Grid item>{e.format("HH:mm")}</Grid>
                                </Grid>
                            </GridListTile>
                        );
                    })}
                </GridList>
            </Grid>
            <Grid item xs={10} lg={11} style={{ position: "relative" }}>
                <GridList cellHeight={30} cols={7} className={classes.gridList}>
                    {dateTimes.map((cell, i) => {
                        var status = appointmentStatus(cell);
                        return (
                            <GridListTile
                                key={i}
                                cols={1}
                                style={{ border: "1px #FFF solid", padding: 0 }}
                                onClick={() => {
                                    switch (status) {
                                        case FREE:
                                            createAppointment(i, cell.places);
                                            break;
                                        case BUSY:
                                            break;
                                        case USER:
                                            editAppointment(cell.appointment);
                                            break;
                                        case ADMIN:
                                            createAppointment(i, cell.places);
                                            break;
                                        default: 
                                    }
                                }}
                            >
                                <Grid
                                    container
                                    style={{
                                        height: "100%",
                                        backgroundColor: calendarColor(status),
                                        cursor: (status !== BUSY && cell.places > 0) || status === USER ? "pointer" : "not-allowed"
                                    }}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid item>{status !== BUSY && cell.places > 0 ? cell.places : ""}</Grid>
                                </Grid>
                            </GridListTile>
                        );
                    })}
                </GridList>
            </Grid>
            <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
        </Grid>
    );
};

export default withStyles(styles)(Week);
