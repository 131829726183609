import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import { FacilityCard, Translate } from "components";

const styles = (theme) => ({
    title: {
        fontSize: 44,
        color: "#3B5441",
        textAlign: "center",
        marginTop: "4%",
        marginBottom: "4%",
    },
});

const FacilityChooser = (props) => {
    const { classes, place } = props;

    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        if (place && place.id) {
            axios.get("/facilitiesByPlaceId/" + place.id).then((facilities) => {
                setFacilities(facilities.data);
            });
        }
    }, [place]);

    return (
        <Grid container>
            <Grid item xs={12} className={classes.title}>
                <Translate id="facilityChooser.facilities" />
            </Grid>
            <Grid item xs={12} className={classes.nemTitle}>
                <Grid container alignItems="center" justify="center" direction="row">
                    {facilities.map((facility) => {
                        return (
                            <Grid item key={facility.id} xs={12} sm={6} md={6} lg={4} xl={3} style={{ margin: "20px 0px" }}>
                                <Grid container justify="center" alignItems="center">
                                    <Grid item xs={11}>
                                        <FacilityCard facility={facility} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(FacilityChooser);
