import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardActionArea, CardContent, CardMedia } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AddImage from "../theme/images/add2.png";
import { Translate } from "components";

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        margin: "auto",
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
        width: "100%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    cardSubTitle: {},
    cardTitle: {
        fontSize: "1.3vw",
        textAlign: "center",
        color: "#3B5441",
    },
});

const AddCard = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} onClick={() => props.add()}>
            <CardActionArea>
                <CardMedia className={classes.media} image={AddImage} title="Contemplative Reptile" />
                <CardContent>
                    <Grid container alignItems="center" style={{ height: 45 }}>
                        <Grid item xs={12} className={classes.cardTitle}>
                            <Translate id="addCard.addNew" />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default withRouter(AddCard);
