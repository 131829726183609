import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { localizeReducer } from "react-localize-redux";
import languageReducer from "./languageReducer";
import userReducer from "./userReducer";
import myAlertReducer from "./myAlertReducer";

export default combineReducers({
    languageReducer,
    firestore: firestoreReducer,
    localize: localizeReducer,
    user: userReducer,
    myAlert: myAlertReducer,
});
