import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/hu";
import { Grid, GridList, GridListTile } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useSelector } from "react-redux";
import { LoginDialog, AddDayAppointment } from "components";
import { useAppointments } from "actions";

const styles = (theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    appointmentBox: {
        position: "absolute",
        width: "14%",
        border: "1px solid black",
    },
});

const getActualMonthDates = (date) => {
    var startOfMonth = moment(date).startOf("month");
    startOfMonth = startOfMonth.subtract(startOfMonth.weekday(), "days");
    var newDates = [...Array(35)].map((e, i) => moment(startOfMonth).add(i, "days"));
    return newDates;
};

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const Month = (props) => {
    const { classes, facility } = props;

    const { isAuthenticated, user } = useSelector((state) => state.user);

    const [dates, setDates] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [dateNr, setDateNr] = useState(moment());
    const [appointment, setAppointment] = useState(null);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [refreshApps, setRefreshApps] = useState(0);
    const [appointments] = useAppointments(facility, dates[0], true, refreshApps);

    useEffect(() => {
        var newDates = getActualMonthDates(props.date);
        setDates(newDates);
    }, [props.date]);

    const createAppointment = (i) => {
        if (!isAuthenticated) {
            setOpenDialog(true);
            return;
        }
        const date = dates[i].format("YYYY-MM-DD");
        const newDate = moment(date);
        setDateNr(newDate);
        setAppointment(undefined);
        setOpenAdd(true);
    };

    const editAppointment = (app) => {
        if (!isAuthenticated) {
            setOpenDialog(true);
            return;
        }
        setDateNr(app.startTime);
        setAppointment(app);
        setOpenAdd(true);
    };

    const getTimeNr = (appointmentStartTime) => {
        var x = appointmentStartTime.diff(dates[0], "day");
        x = x / 7;
        return Math.floor(x);
    };

    return (
        <Grid container>
            <AddDayAppointment
                open={openAdd}
                dateNr={dateNr}
                appointment={appointment}
                facility={facility}
                closeModal={() => {
                    setOpenAdd(false);
                    setRefreshApps(refreshApps + 1);
                }}
                appointments={appointments}
            />
            <Grid item xs={12}>
                <GridList cellHeight={40} cols={7} style={styles.gridList}>
                    {moment.weekdays(true).map((day) => {
                        return (
                            <GridListTile key={day} cols={1} style={{ border: "1px #DDD solid" }}>
                                <Grid container style={{ height: "100%" }} justify="center" alignItems="center">
                                    <Grid item>{capitalize(day.substring(0, 3))}</Grid>
                                </Grid>
                            </GridListTile>
                        );
                    })}
                </GridList>
                <Grid item xs={12} style={{ position: "relative", cursor: "pointer" }}>
                    <GridList cellHeight={80} cols={7} style={styles.gridList}>
                        {dates.map((e, i) => {
                            return (
                                <GridListTile
                                    key={i}
                                    cols={1}
                                    style={{ border: "1px #DDD solid" }}
                                    onClick={() => {
                                        if (
                                            (facility.id !== "JqQ4J6CLBpAJ2ANZkMEe" || (e.day() === 5 || e.day() === 6))  
                                            
                                            // e.format("YYYY-MM-DD") !== "2020-09-04" &&
                                            // e.format("YYYY-MM-DD") !== "2020-09-05"
                                        )
                                            createAppointment(i);
                                    }}
                                >
                                    <Grid
                                        container
                                        justify="flex-end"
                                        alignItems="flex-start"
                                        style={{
                                            height: "100%",
                                            backgroundColor:
                                                (facility.id === "JqQ4J6CLBpAJ2ANZkMEe" && e.day() !== 5 && e.day() !== 6)
                                                // e.format("YYYY-MM-DD") === "2020-09-04" ||
                                                // e.format("YYYY-MM-DD") === "2020-09-05"
                                                    ? "rgba(0, 0, 0, 0.08)"
                                                    : "#81c784",
                                        }}
                                    >
                                        <Grid item>{e.format("DD")}</Grid>
                                    </Grid>
                                </GridListTile>
                            );
                        })}
                    </GridList>

                    {appointments.map((appointment, i) => {
                        if (
                            dates.length === 0 ||
                            appointment.startTime < dates[0] ||
                            appointment.startTime.format("YYYY-MM-DD HH:mm") > dates[dates.length - 1].format("YYYY-MM-DD") + "23:59" ||
                            // (appointment.startTime.day() !== 5 && appointment.startTime.day() !== 6) ||
                            !appointment.isDayAppointment
                        ) {
                            return null;
                        }
                        return (
                            <Grid
                                container
                                justify="flex-end"
                                alignItems="flex-start"
                                key={appointment.id}
                                className={classes.appointmentBox}
                                style={{
                                    top: getTimeNr(appointment.startTime) * 80 + 2 + getTimeNr(appointment.startTime) * 4,
                                    left: `calc(${appointment.startTime.weekday() * (100 / 7)}% + ${appointment.startTime.weekday() / 2}px)`,
                                    height: 80,
                                    backgroundColor: user.id && user.id === appointment.userId ? "#2196f3" : "#DCDCDC",
                                    cursor: user.id && user.id === appointment.userId ? "pointer" : "not-allowed",
                                    border: "none",
                                }}
                                onClick={() => {
                                    if (user.id && user.id === appointment.userId) editAppointment(appointment);
                                }}
                            >
                                <Grid item>{appointment.startTime.format("DD")}</Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
        </Grid>
    );
};

export default withStyles(styles)(Month);
