import React from 'react';
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";

const PageNotFound = () => {
    
    return (
        <Grid container>
            <Grid item>
                Page Not Found 
            </Grid>
        </Grid>
    );
}

export default withRouter(PageNotFound)