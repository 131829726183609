import React from "react";
import "./App.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";
import MyAlert from "components/MyAlert";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

import Themes from "./themes";
import LoginPage from "./views/login/LoginPage";
import MainPage from "./views/MainPage";
import SearchFacility from "./views/SearchFacility";
import FacilityDetail from "./views/FacilityDetail";
import SearchPlace from "./views/SearchPlace";
import AdminSearchFacility from "./views/AdminSearchFacility";
import Appointments from "./views/Appointments";
import PlaceDetail from "./views/PlaceDetail";
import Profile from "./views/Profile";
import { withLocalize } from "react-localize-redux";
import { useDispatch } from "react-redux";

// axios.defaults.baseURL = "http://localhost:5000/idopontotfoglalo/europe-west1/api";
axios.defaults.baseURL = "https://europe-west1-idopontotfoglalo.cloudfunctions.net/api";

export default withLocalize(function App({setActiveLanguage}) {
    
    const dispatch = useDispatch();

    const { isAuthenticated, user } = useSelector((state) => state.user);

    console.log('user', user);
    
    React.useEffect(() => {
        //TODO ideiglenes kivettem, hogy teszteljem, hogy csokken-e a reads-nek a szama a firebase-be
        axios
            .post("/check", { token: Cookies.get("token") })
            .then((resp) => {
                dispatch({ type: "CHECK_TOKEN", payload: { isAuthenticated: true, user: resp.data } });
            })
            .catch((e) => {
                dispatch({ type: "CHECK_TOKEN", payload: { isAuthenticated: false, user: {} } });
            });
    }, [dispatch]);

    React.useEffect(() => {
        const language = Cookies.get("language");
        console.log("isAuthenticated, setActiveLanguage, user", {isAuthenticated, setActiveLanguage, user});
        if (!isAuthenticated) {
            if (language) {
                setActiveLanguage(language);
            }
        } else {
            if (user && user.language) {
                // setActiveLanguage(user.language);
            }
        }
    }, [isAuthenticated, setActiveLanguage, user]);

    return (
        <MuiThemeProvider theme={Themes.default}>
            <CssBaseline />
            <MyAlert />
            <Router>
                <Switch>
                    <Route exact path="/" component={SearchFacility} />
                    {/* <PublicRoute exact path="/login" component={Login} /> */}
                    <Route exact path="/login/:tabId?/:business?" component={LoginPage} />
                    <Route exact path="/mainpage" component={MainPage} />
                    <Route exact path="/searchFacility" component={SearchFacility} />
                    <Route exact path="/facility/:slug" component={FacilityDetail} />
                    <Route exact path="/okland" render={() => (<Redirect to="/place/okland" />)} />
                    <Route exact path="/homorodalmas" render={() => (<Redirect to="/place/homorodalmas" />)} />
                    <Route exact path="/sosfurdo" render={() => (<Redirect to="/facility/sosfurdo" />)} />
                    <Route exact path="/search-place" component={SearchPlace} />
                    <Route exact path="/place/:slug" component={PlaceDetail} />
                    <Route exact path="/profile" component={Profile} />
                    <AdminRoute exact path="/admin" component={AdminSearchFacility} />
                    <AdminRoute exact path="/appointments" component={Appointments} />
                    <Route exact path="/actions" render={() => (<LoginPage tabId={3} />)} />
                </Switch>
            </Router>
        </MuiThemeProvider>
    );

    function AdminRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={
                    (props) =>
                        // user.business ? (
                        React.createElement(component, props)
                    // ) : (
                    //     <Redirect
                    //         to={{
                    //             pathname: "/login2",
                    //             state: {
                    //                 from: props.location,
                    //             },
                    //         }}
                    //     />
                    // )
                }
            />
        );
    }
})
