import React, { useEffect, useState } from "react";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Paper, Modal, Button, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { CustomTimePicker, Loc, loc, Translate } from "components";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAlert }  from "actions";

const styles = (theme) => ({
    modalContainer: {
        position: "absolute",
        width: "400px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    facilityName: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        color: theme.palette.primary.main,
    },
    facilityAddress: {
        textAlign: "center",
        fontSize: 12,
        fontWeight: "bold",
        color: theme.palette.primary.light,
    },
    modalTitle: {
        fontSize: "22px",
        textAlign: "center",
        fontWeight: "bold",
        color: theme.palette.primary.light,
        // marginBottom: "1rem",
    },
    errorMessage: {
        color: "red",
        textAlign: "center",
    },
    modalHeader: {
        //backgroundColor: "#dddddd",
        borderBottom: "1px solid #ddd",
        paddingBottom: "1em",
    },
});

const calcDiffTime = (startTime, endTime) => {
    var start = moment.utc(startTime, "HH:mm");
    var end = moment.utc(endTime, "HH:mm");
    if (end.isBefore(start)) return null;
    var duration = moment.duration(end.diff(start));
    var s = moment.utc(+duration);
    return { time: s, duration: duration };
};

var minutesOfDay = function (m) {
    return m.minute() + m.hour() * 60;
};

const AddWeekAppointment = (props) => {
    const { classes, facility, appointments, closeModal, dateNr, appointment, open, dateTimes } = props;

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const [appointmentId, setAppointmentId] = useState(null);
    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment());
    const [diffTime, setDiffTime] = useState(calcDiffTime(startTime, endTime));
    const [places, setPlaces] = useState(1);
    const [isLocal, setIsLocal] = useState(false);
    const [isExternalUser, setIsExternalUser] = useState(false);
    const [externalUserName, setExternalUserName] = useState("");
    const [externalPhone, setExternalPhone] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorStartTime, setStartTimeError] = useState(false);
    const [errorEndTime, setEndTimeError] = useState(false);
    const [minFreePlaces, setMinFreePlaces] = useState(facility.places);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setDiffTime(calcDiffTime(startTime, endTime));
    }, [startTime, endTime]);

    useEffect(() => {
        var minFreePlaces = facility.places;
        if (dateTimes) {
            dateTimes.forEach((time, i) => {
                if (startTime.isSameOrBefore(time.dateTime) && endTime.isAfter(time.dateTime) && time.places < minFreePlaces) {
                    minFreePlaces = time.places;
                }
            });
            setMinFreePlaces(minFreePlaces);
        }
    }, [dateTimes, facility, startTime, endTime]);

    

    useEffect(() => {

        const openModal = () => {
            if (appointment) {
                setAppointmentId(appointment.id);
                setStartTime(appointment.startTime);
                setEndTime(appointment.endTime);
                setPlaces(appointment.places);
                setIsLocal(appointment.isLocal);
                setIsExternalUser(appointment.isExternalUser);
                if (appointment.isExternalUser) {
                    setExternalUserName(appointment.externalUserName);
                    setExternalPhone(appointment.externalPhone);
                }
            } else {
                setAppointmentId(undefined);
                setStartTime(dateNr);
                var endDateNr;
                console.log("dateNr", dateNr);
                var openZoneItem = facility?.openZone.find(item => item.weekday === dateNr.weekday());
                console.log("open", openZoneItem, dateNr.weekday());
                if (facility.minTimeUnit) {
                    endDateNr = dateNr.clone().add(facility.minTimeUnit, "minutes");
                    //TODO add admin end time to all 
                    // if (user.business && endDateNr.format("HH:mm") <= facility.adminEndTime.format("HH:mm")) {
                    //     endDateNr = dateNr.clone().add(facility.minTimeUnit, "minutes");
                    // } else
                     if (endDateNr.format("HH:mm") > openZoneItem?.endTime ) {
                        endDateNr = dateNr.clone().add(facility.minTime, "minutes");
                    } else  if (user.business && endDateNr.format("HH:mm") > facility.adminEndTime.format("HH:mm")) {
                        endDateNr = dateNr.clone().add(facility.minTime, "minutes");
                    }
                } else {
                    endDateNr = dateNr.clone().add(facility.minTime, "minutes");
                }
                setEndTime(endDateNr);
            }
        };

        if (open) {
            openModal();
        }
    }, [open, appointment, facility, user, dateNr]);

    

    const closeModalLocal = () => {
        setErrorMessage(null);
        closeModal();
    };

    const handleStartTimeChange = (time, facility) => {
        var minTime;
        var maxTime;
        if (user.business && facility.adminStartTime && facility.adminEndTime) {
            minTime = facility.adminStartTime;
            maxTime = facility.adminEndTime;
        } else {
            minTime = facility.startTime;
            maxTime = facility.endTime;
        }
        var newError;
        var timeMinutes = minutesOfDay(time);
        if (minutesOfDay(minTime) < timeMinutes && minutesOfDay(maxTime) > timeMinutes) {
            newError = false;
        } else {
            newError = <Loc id="error.addWeekAppointment" param1={minTime.format("HH:mm")} param2={maxTime.format("HH:mm")} />
        }
        setStartTime(time);
        setErrorMessage(newError);
        setStartTimeError(newError);
        handleEndTimeChange(time.clone().add(facility.minTimeUnit || facility.minTime, "minutes"), facility);
    };

    const handleEndTimeChange = (time, facility) => {
        var minTime;
        var maxTime;
        if (user.business && facility.adminStartTime && facility.adminEndTime) {
            minTime = facility.adminStartTime;
            maxTime = facility.adminEndTime;
        } else {
            minTime = facility.startTime;
            maxTime = facility.endTime;
        }
        var newError;
        var timeMinutes = minutesOfDay(time);
        if (minutesOfDay(minTime) < timeMinutes && minutesOfDay(maxTime) >= timeMinutes) {
            setEndTime(time);
            newError = false;
        } else {
            newError = <Loc id="error.addWeekAppointment.timeInterval" param1={minTime.format("HH:mm")} param2={maxTime.format("HH:mm")} />
        }
        setEndTimeError(newError);
        setErrorMessage(newError);
    };

    const handleExteralUser = (e) => {
        const checked = e.target.checked;
        setIsExternalUser(e.target.checked);
        if (!checked) {
            setExternalUserName(undefined);
            setExternalPhone(undefined);
        }
    };
    const validateTimeInterval = () => {
        var bool = true;
        appointments.forEach((app) => {
            if (app.endTime.isAfter(startTime) && endTime.isAfter(app.startTime) && app.id !== appointmentId && app.userId === user.id) {
                bool = false;
            }
        });
        return bool;
    };

    const saveAppointment = () => {
        if (!validateTimeInterval() && !isExternalUser) {
            setErrorMessage(loc("error.addWeekAppointment.partReserved"));
            return;
        } else if (diffTime <= facility.minTime) {
            setErrorMessage(loc("error.addWeekAppointment.minTimeUnit"));
            return;
        } else if (places < 1 || places > facility.places) {
            //TODO javitasra szorul
            setErrorMessage(loc("error.addWeekAppointment.maxPlaces", {maxPlaces: facility.places}));
            setPlaces(1);
            return;
        } else if (isExternalUser && (!externalPhone || !externalUserName)) {
            setErrorMessage(loc("error.addWeekAppointment.externalUser"));
            return;
        } else if (facility.minTimeUnit && endTime.diff(startTime, "minutes") < facility.minTimeUnit) {
            setErrorMessage(`Egy foglalás időtartama legalabb ${facility.minTimeUnit/60} óra kell legyen! `);
            return;
        } else if (minFreePlaces < places) {
            setErrorMessage(loc("error.addWeekAppointment.maxFreePlaces", {maxFreePlaces: minFreePlaces}))
            return;
        } else if (startTime < moment()) {
            setErrorMessage(loc("error.addWeekAppointment.pastReservation"));
            return;
        } else {
            setErrorMessage(null);
        }
        //TODO meg kell szepiteni, legyen egy appointment a useState-be
        var appointment = {
            startTime,
            endTime,
            duration: diffTime.duration.asMinutes(),
            diffTime: diffTime.time,
            price: isLocal
                ? (((facility.localPrice) * diffTime.duration.asMinutes()) / 60) * places
                : ((facility.price * diffTime.duration.asMinutes()) / 60) * places,
            userId: isExternalUser ? undefined : user.id,
            userName: isExternalUser ? undefined : user.name,
            userPhone: isExternalUser ? undefined : user.phone,
            userEmail: isExternalUser ? undefined : user.email,
            facilityId: facility.id,
            paid: false,
            paidDate: null,
            places,
            isLocal,
            isExternalUser,
            externalPhone,
            externalUserName,
        };
        setIsLoading(true);
        if (!errorMessage) {
            axios
                .post("/appointment/", { appointment, id: appointmentId ? appointmentId : 0 })
                .then((appoitnment) => {
                    closeModalLocal();
                    axios.post("/email-to-user", { type: "appointmentSaved", appointment: appointment, facility: facility });
                    // setFacility(facility.data);
                    //TODO egyszerusodik ha atirod a fentit
                    setIsExternalUser(false);
                    setExternalPhone("");
                    setExternalUserName("");
                    setIsLocal(false);
                    setPlaces(1);
                    setIsLoading(false);
                    dispatch(setAlert(true, "success", loc("addWeekAppointment.savedSuccessfully")));
                })
                .catch((error) => {
                    setErrorMessage(loc("error.addWeekAppointment.saveError"));
                });
        }
    };

    const deleteAppointment = () => {
        if (startTime < moment()) {
            setErrorMessage(loc("error.addWeekAppointment.deletePastAppoitnment"));
            return;
        }
        axios.post("/appointmentDelete/", { id: appointmentId }).then((e) => {
            closeModalLocal();
            axios.post("/email-to-user", { type: "deleteAppointment", appointment: appointment, facility: facility });
            dispatch(setAlert(true, "success", loc("addWeekAppointment.deletedSuccessfully")));
        });
    };

    const handlePrice = () => {
        if (facility.price && facility.price !== 0 ){
            var price = 0;
            if (isLocal) {
                price = (((facility.localPrice) * diffTime.duration.asMinutes()) / facility.minTime) * places;
            } else {
                price = ((facility.price * diffTime.duration.asMinutes()) / facility.minTime) * places;
            }
            return loc("addWeekAppointment.appointmentPrice", {price: price});
        } else {
            return ""
        }
       
    }

    return (
        <Modal open={open} onClose={closeModalLocal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <Paper>
                <Grid container className={classes.modalContainer}>
                    <Grid item xs={12}>
                        <Grid container className={classes.modalHeader}>
                            <Grid item xs={12} className={classes.facilityName}>
                                {facility.name}
                            </Grid>
                            <Grid item xs={12} className={classes.facilityAddress}>
                                {facility.location}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.modalTitle}>
                                        <Loc value="addWeekAppointment.reservation" />
                                    </Grid>
                                    {errorMessage && (
                                        <Grid item xs={12} className={classes.errorMessage}>
                                            {errorMessage}
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={5}>
                                                <CustomTimePicker
                                                    label={loc("addWeekAppointment.starting")}
                                                    value={startTime}
                                                    setSelectedTime={(time) => handleStartTimeChange(time, facility)}
                                                    errorMessage={errorStartTime}
                                                    minutesStep={facility.minTime}
                                                />
                                            </Grid>
                                            <Grid item xs={2} />
                                            <Grid item xs={5}>
                                                <CustomTimePicker
                                                    label={loc("addWeekAppointment.ending")}
                                                    value={endTime}
                                                    setSelectedTime={(time) => handleEndTimeChange(time, facility)}
                                                    errorMessage={errorEndTime}
                                                    minutesStep={facility.minTime}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            id="places"
                                            value={places}
                                            onChange={(e) => setPlaces(e.target.value)}
                                            margin="normal"
                                            label={loc("addWeekAppointment.places")}
                                            type="number"
                                            fullWidth
                                            inputProps={{ min: "0", max: facility.places, step: "1" }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={5}>
                                        <Grid container alignItems="center" style={{ height: "100%" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isLocal}
                                                        onChange={(e) => setIsLocal(e.target.checked)}
                                                        name="checkedA"
                                                        color="primary"
                                                    />
                                                }
                                                label={loc("addWeekAppointment.localUser")}
                                            />
                                        </Grid>
                                        {/* <TextField
                                        id="localPlaces"
                                        value={localPlaces}
                                        onChange={(e) => setLocalPlaces(e.target.value)}
                                        margin="normal"
                                        label="Foglalandó helyek"
                                        type="number"
                                        fullWidth
                                        inputProps={{ min: "0", max: facility.places, step: "1" }}
                                    /> */}
                                    </Grid>
                                    {diffTime && (
                                        <Grid item xs={12}>
                                            <Grid container justify="center" alignItems="center">
                                                <Grid item xs={6}>
                                                    <Translate>
                                                        {({ translate }) => <>
                                                            {translate("addWeekAppointment.time") + " " +
                                                                (diffTime.time.format("H") !== "0"
                                                                    ? diffTime.time.format("H") + " " + translate("addWeekAppointment.hour")
                                                                    : null + diffTime.time.format("m") !== "0"
                                                                    ? diffTime.time.format("m") + " " + translate("addWeekAppointment.minute")
                                                                    : null)}
                                                        </>}
                                                    </Translate>
                                                </Grid>
                                                <Grid item xs={6}>
                                                   {handlePrice()}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {user && user.business && (
                                        <Grid container>
                                            <Grid item className={classes.externalUser} xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isExternalUser}
                                                            onChange={handleExteralUser}
                                                            name="isExternalUser"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={loc("addWeekAppointment.externalUser")}
                                                />
                                            </Grid>
                                            {isExternalUser && (
                                                <Grid item className={classes.externalUser} xs={12}>
                                                    <TextField
                                                        id="externalUserName"
                                                        value={externalUserName}
                                                        onChange={(e) => setExternalUserName(e.target.value)}
                                                        margin="normal"
                                                        label={loc("addWeekAppointment.userName")}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                            {isExternalUser && (
                                                <Grid item className={classes.externalUser} xs={12}>
                                                    <TextField
                                                        id="externalUserPhone"
                                                        value={externalPhone}
                                                        onChange={(e) => setExternalPhone(e.target.value)}
                                                        margin="normal"
                                                        label={loc("addWeekAppointment.userPhoneNr")}
                                                        fullWidth
                                                        type="phone"
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={4}>
                                            <Button variant="outlined" color="primary" onClick={closeModalLocal} fullWidth>
                                                <Loc value="general.cancel" />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="outlined" color="primary" onClick={deleteAppointment} fullWidth disabled={isLoading}>
                                                <Loc value="general.delete" />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="outlined" color="primary" onClick={saveAppointment} fullWidth disabled={isLoading}>
                                                <Loc value="general.save" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default withStyles(styles)(AddWeekAppointment);
