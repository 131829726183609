import React, { useState, useRef, useEffect } from "react";
import { Grid, Paper, TextField, TextareaAutosize, Slider, Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import Files from "react-butterfiles";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { ImageEdit, Map, CustomTimePicker } from "components";
import RichTextEditor from "react-rte";

const styles = (theme) => ({
    modalContainer: {
        width: "500px",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%",
    },
    textarea: {
        border: "1px solid #CACACA",
    },
    avatar: {
        height: 150,
        width: 150,
    },
    backgroundPhoto: {
        height: 150,
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#BDBDBD",
    },
    box: {
        cursor: "pointer",
        width: 128,
        height: 128,
        display: "inline-block",
        position: "relative",
        float: "left",
        margin: 2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "lightgrey",
        borderImage: "initial",
        overflow: "hidden",
        textAlign: "center",
        color: "lightgrey",
    },
    galeryImage: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "lightgrey",
    },
    mapSize: {
        width: "100%",
        height: "30vh",
    },
});

const AddFacilityModal = (props) => {
    const { classes, closeModal, propsFacility } = props;

    const { user } = useSelector((state) => state.user);

    const editorRef = useRef(null);
    const [facility, setFacility] = useState({});
    const [avatar, setAvatar] = useState(null);
    const [backgroundPhoto, setBackgroundPhoto] = useState(null);
    const [avatarSliderValue, setAvatarSliderValue] = useState(1);
    const [descriptionValue, setDescriptionValue] = useState(RichTextEditor.createEmptyValue());

    useEffect(() => {
        if (propsFacility) {
            setFacility(propsFacility);
        }
    }, [propsFacility]);

    const handleDescriptionValue = (value) => {
        console.log("value", value.toString("html"));
        setDescriptionValue(value);
    };

    const handleUploadAvatar = (event) => {
        var file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                setAvatar(reader.result);
            };
        }
    };

    const handleUploadBackgroundPhoto = (event) => {
        var file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                setBackgroundPhoto(reader.result);
            };
        }
    };

    const handleAvatarSliderValue = (value) => {
        setAvatarSliderValue(value);
        if (editorRef && editorRef.current) {
            console.log(editorRef.current);
            setBackgroundPhoto(editorRef.current.canvas);
        }
        return value;
    };

    const setAvatarPreview = (value) => {
        setFacility({ ...facility, avatarPreview: value });
    };

    const setBackgroundPreview = (value) => {
        setFacility({ ...facility, backgroundPreview: value });
    };

    const handleField = (e) => {
        setFacility({ ...facility, [e.target.name]: e.target.value });
    };

    const handleFiles = (files) => {
        var newFiles = [];
        if (!facility.gallery) {
            newFiles = [files[0]];
        } else {
            newFiles = [...facility.gallery, files[0]];
        }
        setFacility({ ...facility, gallery: newFiles });
    };

    const handleErrors = (files) => {
        console.log("handleErrors", files);
    };

    const saveFacility = () => {
        console.log("save facility");
        // if (!validateTimeInterval()) {
        //     console.log("itt vagy");
        //     setErrorMessage("A kiválsztott időpont egy része vagy egésze már foglalt. Kérlek válassz másikat.");
        //     return;
        // } else {
        //     setErrorMessage(null);
        // }
        var newFacility = facility;

        newFacility.userId = user.id;
        //TODO selecteket kell csinalni ezeknek
        newFacility.country = "Románia";
        newFacility.county = "Hargita";
        axios.post("/facility/", newFacility).then((facility) => {
            console.log("facility", facility.data);
            // setOpen(false);
            // getAppointments();
        });
    };

    return (
        <Paper>
            <Grid container className={classes.modalContainer}>
                <Grid container>
                    <Grid item xs={10} style={{ fontSize: 18, fontWeight: "bold" }}>
                        Létesítmény hozzáadása, szerkesztése
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "end" }}>
                        <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <form noValidate autoComplete="off">
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <TextField name="facilityName" label="Létesítmény neve" className={classes.input} onChange={handleField} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    name="description"
                                    aria-label="empty textarea"
                                    placeholder="Leírás"
                                    className={classnames(classes.input, classes.textarea)}
                                    rowsMin={5}
                                    rowsMax={10}
                                    onChange={handleField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="country"
                                    label="Ország"
                                    value="Románia"
                                    disabled={true}
                                    className={classes.input}
                                    onChange={handleField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="county"
                                    label="Megye"
                                    value="Hargita"
                                    disabled={true}
                                    className={classes.input}
                                    onChange={handleField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="location" label="Helység" className={classes.input} onChange={handleField} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="streetNr" label="Utca, házszám" className={classes.input} onChange={handleField} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="price" type="number" label="Ára" className={classes.input} required={true} onChange={handleField} />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTimePicker
                                    label="Nyitási időpont"
                                    value={facility.startTime}
                                    setSelectedTime={(time) => setFacility({ ...facility, startTime: time })}
                                    // errorMessage={errorStartTime}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTimePicker
                                    label="Zárási időpont"
                                    value={facility.endTime}
                                    setSelectedTime={(time) => setFacility({ ...facility, endTime: time })}
                                    // errorMessage={errorStartTime}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                Logó/Avatar
                            </Grid>
                            <Grid item>
                                <label htmlFor={avatar ? "" : "avatarId"}>
                                    <ImageEdit avatar={avatar} setPreview={setAvatarPreview} scale={avatarSliderValue} />
                                </label>
                                <input
                                    name="newAvatar"
                                    accept="image/*"
                                    className={classes.input}
                                    id="avatarId"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleUploadAvatar}
                                />
                            </Grid>
                            <Grid item style={{ height: 150 }}>
                                <Slider
                                    orientation="vertical"
                                    getAriaValueText={handleAvatarSliderValue}
                                    min={-10}
                                    step={0.1}
                                    defaultValue={1}
                                    valueLabelDisplay="auto"
                                    max={5}
                                    aria-labelledby="vertical-slider"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                Háttérkép
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="backgroundPhoto"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleUploadBackgroundPhoto}
                                />
                                <label htmlFor={backgroundPhoto ? "" : "backgroundPhoto"}>
                                    <ImageEdit
                                        avatar={backgroundPhoto}
                                        setPreview={setBackgroundPreview}
                                        scale={1}
                                        borderRadius={0}
                                        height={150}
                                        width={435}
                                    />
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                Galléria
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Files
                                        multiple
                                        convertToBase64
                                        accept={["image/jpg", "image/jpeg", "image/png"]}
                                        onError={handleErrors}
                                        onSuccess={(files) =>
                                            // Will append images at the end of the list.
                                            handleFiles(files)
                                        }
                                        maxSize="10mb"
                                    >
                                        {({ browseFiles, getDropZoneProps }) => (
                                            <Grid
                                                container
                                                {...getDropZoneProps({
                                                    // className: gallery + (this.state.dragging ? " dragging" : ""),
                                                    onDragEnter: () => this.setState({ dragging: true }),
                                                    onDragLeave: () => this.setState({ dragging: false }),
                                                    onDrop: () => this.setState({ dragging: false }),
                                                })}
                                            >
                                                <Grid container>
                                                    {facility.gallery &&
                                                        facility.gallery.map((image, index) => (
                                                            <Grid
                                                                item
                                                                className={classes.box}
                                                                key={index}
                                                                onClick={() => {
                                                                    browseFiles({
                                                                        onErrors: handleErrors,
                                                                        onSuccess: (files) => {
                                                                            // Will insert images after the clicked image.
                                                                            handleFiles(files);
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <div
                                                                    style={{ backgroundImage: `url("${image.src.base64}")` }}
                                                                    className={classes.galeryImage}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    <Grid
                                                        item
                                                        className={classes.box}
                                                        onClick={() => {
                                                            browseFiles({
                                                                onErrors: handleErrors,
                                                                onSuccess: (files) => {
                                                                    // Will append images at the end of the list.
                                                                    handleFiles(files);
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <Grid container alignItems="center" justify="center" style={{ height: "100%" }}>
                                                            <Grid item>+</Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Files>
                                    {/* {this.state.errors.length > 0 && <div>An error occurred.</div>} */}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                Jelöld meg a létesítmény helyét
                            </Grid>
                            <Grid item xs={12}>
                                <Map
                                    zoom={12}
                                    className={classes.mapSize}
                                    position={facility.latLng}
                                    mapClick={(latLng) => setFacility({ ...facility, latLng })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outlined" color="primary" onClick={saveFacility}>
                                    Mentés
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <RichTextEditor value={descriptionValue} onChange={handleDescriptionValue} />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default withStyles(styles)(withRouter(AddFacilityModal));
