import React from "react";
import AvatarEditor from "react-avatar-editor";

class ImageEdit extends React.Component {
    onClickSave = () => {
        if (this.editor) {
            const canvas = this.editor.getImage().toDataURL();
            this.props.setPreview(canvas);
        }
    };

    setEditorRef = (editor) => (this.editor = editor);

    render() {
        var { avatar, scale, borderRadius, width, height } = this.props;

        return (
            <AvatarEditor
                ref={this.setEditorRef}
                image={avatar}
                width={width ? width : 150}
                height={height ? height : 150}
                border={0}
                borderRadius={borderRadius !== undefined ? borderRadius : 90}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={scale}
                rotate={0}
                onLoadSuccess={this.onClickSave}
                onMouseUp={avatar ? this.onClickSave : () => {}}
                style={{ backgroundColor: "#eaeaea" }}
            />
        );
    }
}

export default ImageEdit;
