import React from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "actions";

const styles = (theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MyAlert = (props) => {

    const { classes } = props;
    const dispatch = useDispatch();

    const { open, type, message } = useSelector((state) => state.myAlert);
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setAlert(false));
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={type}>
                        {message}
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(MyAlert);
