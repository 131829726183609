import React, { useState } from "react";
import { Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { withRouter, useParams } from "react-router-dom";
// import classnames from "classnames";
import { loc, Login, Register, ForgetPassword, FirebaseActions } from "components";

// styles
import useStyles from "./styles";

// logo
import calendar from "./calendar.svg";

const LoginPage = (props) => {
    const classes = useStyles();
    let { tabId } = useParams();

    const [activeTabId, setActiveTabId] = useState(tabId ? parseInt(tabId) : props.tabId ? props.tabId : 0);

    const renderTabs = () => {
        return (
            <Tabs
                value={activeTabId}
                onChange={(e, id) => {
                    setActiveTabId(id);
                }}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={loc("login.login")} classes={{ root: classes.tab }} />
                <Tab label={loc("login.signup")} classes={{ root: classes.tab }} />
            </Tabs>
        )
    }

    const renderComponent = () => {
        let component;
        switch (activeTabId) {
            case 0:
                component = (
                    <React.Fragment>
                        {renderTabs()}
                        <Login setActiveTabId={setActiveTabId} />
                    </React.Fragment>
                );
                break;
            case 1:
                component = (
                    <React.Fragment>
                        {renderTabs()}
                        <Register setActiveTabId={setActiveTabId} />
                    </React.Fragment>
                );
                break;
            case 2:
                component = <ForgetPassword />;
                break;
            case 3:
                component = <FirebaseActions />;
                break;
            default:
                break;
        }
        return component;
    }

    return (
        <Grid container className={classes.container}>
            <Grid item className={classes.logotypeContainer}>
                <img src={calendar} alt="logo" className={classes.logotypeImage} />
                <Typography className={classes.logotypeText}>Időpontfoglaló</Typography>
            </Grid>
            <Grid className={classes.formContainer}>
                <Grid className={classes.form}>
                    { renderComponent() }
                </Grid>
                <Typography color="primary" className={classes.copyright}>
                    © 2021 Rigo Matyas II, LLC. All rights reserved.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default withRouter(LoginPage);
