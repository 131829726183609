import React, { useState, useEffect } from "react";
import { Box, Grid, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, Link } from "react-router-dom";

import background from "../theme/images/homokora3.jpg";
import { Header, FacilityCard, ServiceCard } from "components";
import { orange } from "@material-ui/core/colors";
import axios from "axios";

const styles = (theme) => ({
    fullPage: {
        backgroundPosition: "center right",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundColor: "#000",
        backgroundImage: `url(${background})`,
        display: "table",
        height: "100%",
        position: "relative",
        width: "100%",
        // @media (max-width: 600px) {
        //   background-image: url(${props =>
        //     props.backgroundImageMobil ? props.backgroundImageMobil : ""});
        // }
    },
    bImage: {
        backgroundPosition: "center right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#000",
        backgroundImage: `url(${background})`,
    },
    titleText: {
        fontSize: "5vw",
        color: "#3B5441",
        fontWeight: "bold",
    },
    subTitleText: {
        fontSize: "2vw",
        color: "#3B5441",
    },
    popularTitleText: {
        fontSize: 44,
        color: "#3B5441",
        textAlign: "center",
        marginBottom: "4%",
    },
    serviceCardWrapper: {
        minWidth: "75%",
    },
    cardContainer: {
        marginLeft: "auto",
        marginRight: "auto",
        flexGrow: 0,
        maxWidth: `100%`,
        flexBasis: `100%`,
    },
    rentButton: {
        marginTop: "3%",
    },
});

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(orange[900]),
        backgroundColor: orange[800],
        "&:hover": {
            backgroundColor: orange[900],
        },
        textTransform: "none",
        fontFamily: "Montserrat",
        fontSize: "1.5vw",
        borderRadius: 16,
    },
}))(Button);

const MainPage = (props) => {
    const { classes } = props;

    const [topFacilities, setTopFacilities] = useState([]);

    useEffect(() => {
        axios.get("/top-facilities").then((facilities) => {
            setTopFacilities(facilities.data);
        });
    }, []);

    return (
        <>
            <Header />
            <Box height="100%" width="100%" className={classes.bImage}>
                <Grid container style={{ height: "100%" }}>
                    <Grid item xs={8} style={{ height: "100%" }}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            className={classes.titleContainer}
                            style={{ height: "100%" }}
                        >
                            <Grid item className={classes.titleText}>
                                Időpontfoglalás Online
                            </Grid>
                            <Grid item className={classes.subTitleText} style={{ marginTop: "2%" }}>
                                Könnyedén lefoglalhatod kedvenc helyeidet,
                            </Grid>
                            <Grid item className={classes.subTitleText}>
                                vagy fedezz fel újakat
                            </Grid>
                            <Grid item className={classes.rentButton}>
                                <Link to="searchFacility" style={{ textDecoration: "none" }}>
                                    <ColorButton variant="contained" color="primary" size="large">
                                        Foglalj most
                                    </ColorButton>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </Box>
            <Grid container style={{ marginTop: "5%", marginLeft: "auto", marginRight: "auto" }}>
                <Grid item xs={12} className={classes.popularTitleText}>
                    Legnépszerűbb létesítmények
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={10} className={classes.cardContainer}>
                        {topFacilities.map((facility) => (
                            <Grid item key={facility.id} xs={3}>
                                <FacilityCard facility={facility} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ marginTop: "5%", marginLeft: "auto", marginRight: "auto" }}>
                <Grid item xs={12} className={classes.popularTitleText}>
                    Szolgáltatások
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="column" justify="center" alignItems="center" spacing={10} className={classes.cardContainer}>
                        <Grid item className={classes.serviceCardWrapper}>
                            <ServiceCard title={"Foglalás"} subtitle="Online a világ bármely pontjáról, bármilyen eszközről" />
                        </Grid>
                        <Grid item className={classes.serviceCardWrapper}>
                            <ServiceCard title={"Foglalás"} subtitle="Online a világ bármely pontjáról" textPosition="right" />
                        </Grid>
                        <Grid item className={classes.serviceCardWrapper}>
                            <ServiceCard title={"Foglalás"} subtitle="Online a világ bármely pontjáról" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(withStyles(styles)(MainPage));
