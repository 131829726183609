import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

export const useFacility = (slug, refresh) => {
    const [facility, setFacility] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchFacility = async () => {
            //if (!slug) slug = "sosfurdo";
            if (slug) {
                await axios.get(`/facilityBySlug/${slug}`).then(async (res) => {
                    var facility = res.data;
                    facility.startTime = moment(facility.startTime, "HH:mm");
                    facility.endTime = moment(facility.endTime, "HH:mm");
                    setFacility(facility);
                    setIsLoading(false);
                });
            }
        };
        fetchFacility();
    }, [slug, refresh]);

    return [facility, isLoading];
};

export const useFacilitiesByAdminId = (adminId, refresh) => {
    const [facilities, setFacilities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchFacilities = async () => {
            if (adminId) {
                await axios.get(`/facilitiesByAdminId/${adminId}`).then(async (res) => {
                    var facilities = res.data;
                    setFacilities(facilities);
                    setIsLoading(false);
                });
            }
        };
        fetchFacilities();
    }, [adminId, refresh]);

    return [facilities, isLoading];
};