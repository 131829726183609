import React, { useState, useEffect, useRef } from "react";
import { Grid, TableContainer, TableBody, TableCell, Paper, Table, TableHead, TableRow, Button, Link } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { Translate, Header, AddWeekAppointment, AddDayAppointment, Loc } from "components";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import EditIcon from "@material-ui/icons/Edit";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { useAppointments, useFacilitiesByAdminId, useSubFacilities } from "actions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Select from "react-select";
import { useSelector } from "react-redux";

const styles = (theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    searchContainer: {
        padding: "3% 10px",
        //backgroundColor: "#eaeaea",
    },
    inputContainer: {
        padding: "1%",
    },
    title: {
        fontSize: 44,
        color: "#3B5441",
        textAlign: "center",
        marginTop: "4%",
    },
    selectContainer: {
        paddingBottom: "2em",
        borderBottom: "1px solid #3B5441",
        marginBottom: "1em"
    },
    selectText: {
        fontSize: 17,
    },
    selectTextMobile: {
        fontSize: 16,
        textAlign: "center",
        marginBottom: 15
    }
});

const Appointments = (props) => {
    const { classes } = props;

    const isMobile = useMediaQuery('(max-width:600px)');

    const componentRef = useRef();

    const { user } = useSelector((state) => state.user);

    const [facilities] = useFacilitiesByAdminId(user.id);

    const [selectedFacility, setSelectedFacility] = useState({});

    const [subFacilities] = useSubFacilities(selectedFacility.id, 0);

    const [selectedSubFacility, setSelectedSubFacility] = useState({});

    const [currentDate, setCurrentDate] = useState(moment());
    const [openAddWeek, setOpenAddWeek] = useState(false);
    const [openAddDay, setOpenAddDay] = useState(false);
    const [appointment, setAppointment] = useState(null);
    const [refreshApps, setRefreshApps] = useState(0);
    const [appointments] = useAppointments(selectedSubFacility ? selectedSubFacility : selectedFacility, currentDate, false, refreshApps);

    useEffect(() => {
        if (facilities && facilities.length !== 0) {
            var facility = facilities[0];
            setSelectedFacility({...facility, value: facility.name, label: facility.name})
            setSelectedSubFacility({});
            setRefreshApps(r => r+1);
        }
    }, [facilities]);

    useEffect(() => {
        if (subFacilities && subFacilities.length !== 0) {
            var facility = subFacilities[0];
            setSelectedSubFacility({...facility, value: facility.label, label: facility.label})
            setRefreshApps(r => r+1);
        }
    }, [subFacilities]);

    const editAppointment = (app) => {
        setAppointment(app);
        //TODO vegleges megoldas kell, kell egy tipus az appointmentbe (day, week)
        if (selectedFacility && app.places === selectedFacility.places && app.price === 500) {
            setOpenAddDay(true);
        } else {
            setOpenAddWeek(true);
        }
    };
    const handleFacilityChange = (value) => {
        setSelectedFacility(value);
        setSelectedSubFacility(null);
        setRefreshApps(refreshApps+1);
    }

    const handleSubFacilityChange = (value) => {
        setSelectedSubFacility(value);
        setRefreshApps(refreshApps+1);
    }

    return (
        <>
            <Header />
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={classes.title}>
                    <Loc value="appointments.reservation" />
                </Grid>
                <Grid item xs={12} lg={10} xl={8} style={{ marginBottom: "4%", padding: 5 }}>
                    <ReactToPrint
                        trigger={() => (
                            <Link>
                                <Grid container direction="row" justify="flex-end">
                                    <Grid item xs={1} style={{ cursor: "pointer", textAlign: "right", marginRight: "1%"}}>
                                        <PrintIcon />
                                    </Grid>
                                    <Grid item xs={2} style={{ fontSize: 18, cursor: "pointer" }}>
                                        <Loc value="appointments.print" />
                                    </Grid>
                                </Grid>
                            </Link>
                        )}
                        content={() => componentRef.current}
                    />
                </Grid>
                <Grid item xs={12} lg={10} xl={8} ref={componentRef}>
                    {facilities.length>0 && <Grid container alignItems="center" justify="center" className={classes.selectContainer2}>
                        <Grid item xs={12} md={6} className={isMobile ? classes.selectTextMobile : classes.selectText}>
                            <Loc value="appointments.reservationFacility" />
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.inputContainer}>
                            <Select
                                value={selectedFacility}
                                options={facilities.map(facility => { return {...facility, value: facility.name, label: facility.name}; })}
                                placeholder={<Translate id={"selectFacilityTypePlaceholder"} />}
                                isClearable={false}
                                onChange={handleFacilityChange}
                            />
                        </Grid>
                    </Grid>}
                    {subFacilities.length>1 && <Grid container alignItems="center" justify="center" className={classes.selectContainer}>
                        <Grid item xs={12} md={6} className={isMobile ? classes.selectTextMobile : classes.selectText}>
                            <Loc value="appointments.reservationSubFacility" />
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.inputContainer}>
                            <Select
                                value={selectedSubFacility}
                                options={subFacilities.map(facility => { return {...facility, value: facility.label, label: facility.label}; })}
                                placeholder={<Translate id={"selectFacilityTypePlaceholder"} />}
                                isClearable={false}
                                onChange={handleSubFacilityChange}
                            />
                        </Grid>
                    </Grid>}
                    <Grid container justify="center">
                        <Grid item xs={3} style={{ textAlign: "end", fontSize: 28 }}>
                            <ArrowBackIcon
                                style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
                                onClick={() => setCurrentDate(currentDate.clone().subtract(1, "days"))}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "center", fontSize: 28 }}>
                            {currentDate.format("YYYY-MM-DD")}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "start", fontSize: 28, cursor: "pointer" }}>
                            <ArrowForwardIcon
                                style={{ width: "1.5em", height: "1.5em" }}
                                onClick={() => setCurrentDate(currentDate.clone().add(1, "days"))}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ marginBottom: "5%" }}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"><Loc value="appointments.starting" /></TableCell>
                                            <TableCell align="center"><Loc value="appointments.ending" /></TableCell>
                                            <TableCell align="center"><Loc value="appointments.personNr" /></TableCell>
                                            <TableCell align="center"><Loc value="appointments.price" /></TableCell>
                                            <TableCell align="center"><Loc value="appointments.username" /></TableCell>
                                            <TableCell align="center"><Loc value="appointments.phoneNr" /></TableCell>
                                            <TableCell align="center"><Loc value="appointments.edit" /></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {appointments.filter(appointment => appointment.startTime.clone().startOf("day").isSame(currentDate.startOf("day"))).map((appointment) => {
                                                return (
                                                    <TableRow key={appointment.id}>
                                                        <TableCell align="center">{appointment.startTime.format("HH:mm")}</TableCell>
                                                        <TableCell align="center">{appointment.endTime.format("HH:mm")}</TableCell>
                                                        <TableCell align="center">{appointment.places}</TableCell>
                                                        <TableCell align="center">{appointment.price}</TableCell>
                                                        <TableCell align="center">
                                                            {appointment.isExternalUser ? appointment.externalUserName : appointment.userName}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {appointment.isExternalUser ? appointment.externalPhone : appointment.userPhone}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Button onClick={() => editAppointment(appointment)}>
                                                                <EditIcon />
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            } 
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {selectedFacility && 
            <AddWeekAppointment
                open={openAddWeek}
                appointment={appointment}
                facility={selectedFacility}
                closeModal={() => {
                    setOpenAddWeek(false);
                    setRefreshApps(refreshApps + 1);
                }}
                appointments={appointments}
            />}
            {selectedFacility && <AddDayAppointment
                open={openAddDay}
                appointment={appointment}
                facility={selectedFacility}
                closeModal={() => {
                    setOpenAddDay(false);
                    setRefreshApps(refreshApps + 1);
                }}
                appointments={appointments}
            />}
        </>
    );
};

export default withRouter(withStyles(styles)(Appointments));
