const inicialState = {
  language: "ENG",
}

export default (state = inicialState, action) => {
  switch (action.type) {
    case "SIMPLE_ACTION":
      return {
        result: action.payload,
      }
    default:
      return state
  }
}
