import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    container: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
    },
    logotypeContainer: {
        backgroundColor: theme.palette.primary.main,
        width: "60%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    logotypeImage: {
        width: 165,
        marginBottom: theme.spacing(4),
    },
    logotypeText: {
        color: "white",
        fontWeight: 500,
        fontSize: 84,
        [theme.breakpoints.down("md")]: {
            fontSize: 48,
        },
    },
    logotypeText2: {
        color: "#3EA2E5",
        fontWeight: 500,
        fontSize: 54,
        [theme.breakpoints.down("md")]: {
            fontSize: 24,
        },
    },
    formContainer: {
        width: "40%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
    },
    form: {
        width: 320,
    },
    tab: {
        fontWeight: 400,
        fontSize: 18,
    },
    googleButton: {
        marginTop: theme.spacing(6),
        boxShadow: theme.customShadows.widget,
        backgroundColor: "white",
        width: "100%",
        textTransform: "none",
    },
    googleButtonCreating: {
        marginTop: 0,
    },
    googleIcon: {
        width: 30,
        marginRight: theme.spacing(2),
    },
    creatingButtonContainer: {
        marginTop: theme.spacing(2.5),
        height: 46,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    copyright: {
        marginTop: theme.spacing(4),
        whiteSpace: "nowrap",
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: theme.spacing(2),
        },
    },
    facebookBtn: {
        padding: "8px 22px",
        height: 46,
        fontWeight: 700,
        color: "#fff",
        cursor: "pointer",
        display: "inline-block",
        fontSize: "0.9375rem",
        textDecoration: "none",
        transition: "background-color .3s,border-color .3s",
        backgroundColor: "#4c69ba",
        border: "none",
        marginTop: theme.spacing(3),
        width: "100%",
        alignItems: "inherit",
        justifyContent: "inherit",
    },
}));
