import React, { useState, useEffect } from "react";
import { Grid, Avatar, Tabs, Tab, Paper, Typography, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, useParams } from "react-router-dom";
import { Header, Map, FacilityChooser, Translate, loc, Loc } from "components";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactHtmlParser from "react-html-parser";

const styles = (theme) => ({
    coverPhoto: {
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#000",
        paddingTop: "40%",
        width: "100%",
    },
    avatarContainer: {
        marginBottom: "1em",
        marginTop: "-8%",
    },
    avatar: {
        width: "100%",
        height: "100%",
        border: "5px solid white",
    },
    placeName: {
        textAlign: "center",
        fontSize: 24,
        fontWeight: "bold",
        color: "#81c784",
    },
    placeAddress: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: "bold",
        color: "#81c784",
    },
    placeDescription: {
        textAlign: "justify",
        padding: 15,
    },
    placeMap: {
        padding: 15,
    },
    mapSize: {
        width: "100%",
        height: "30vh",
    },
    imageGallery: {
        img: {
            maxHeight: 300,
        },
    },
    description: {
        fontSize: "12px",
        color: "#002539",
    },
    moreDescription: {
        color: "#81c784",
        fontWeight: "bold",
        textTransform: "none",
        backgroundColor: "rgba(74, 74, 74, 0.07)",
        borderRadius: 8,
        "&:hover": {
            backgroundColor: "rgba(74, 74, 74, 0.1)",
        },
    },
});

const PlaceDetail = (props) => {
    const { classes } = props;

    let { slug } = useParams();

    const [place, setPlace] = useState({});
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        axios.get(`/place/${slug}`).then((place) => {
            setPlace(place.data);
        });
    }, [slug]);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const loadTabData = () => {
        switch (tabIndex) {
            case 0:
                return loadFacilityChooser();
            case 1:
                return loadAbout();
            case 2:
                return loadPhotos();
            case 3:
                return loadReviews();
            default:
                break;
        }
    };

    const loadFacilityChooser = () => {
        return (
            <>
                <Grid container>
                    <Grid item xs={12}>
                        <FacilityChooser place={place} />
                    </Grid>
                </Grid>
                .
            </>
        );
    };

    const loadShortAbout = () => {
        const latLng = place.latLng;
        return (
            <Paper>
                <Grid container>
                    <Grid item xs={12} lg={6} className={classes.placeDescription}>
                        <Grid container direction="column" alignItems="flex-end">
                            <Grid item xs={12}>
                                <Typography component={"div"} className={classes.description}>
                                    {ReactHtmlParser(place.shortDescription)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button className={classes.moreDescription} onClick={() => setTabIndex(1)}>
                                    <Translate id="placeDetail.knowMore" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.placeMap}>
                        {latLng && (
                            <Map zoom={14} className={classes.mapSize} position={[latLng.lat, latLng.lng]} center={[latLng.lat, latLng.lng]} />
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const loadAbout = () => {
        const latLng = place.latLng;
        return (
            <Grid container>
                <Grid item xs={12} lg={6} className={classes.placeDescription}>
                    <Typography component={"div"} className={classes.description}>
                        {ReactHtmlParser(place.description)}
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6} className={classes.placeMap}>
                    {latLng && <Map zoom={12} className={classes.mapSize} position={[latLng.lat, latLng.lng]} center={[latLng.lat, latLng.lng]} />}
                </Grid>
            </Grid>
        );
    };

    const loadPhotos = () => {
        var gallery = [];
        if (place.gallery) {
            if (place.gallery.length === 0) {
                return (
                    <Grid item xs={12} style={{ fontSize: "1.5em", textAlign: "center", padding: "30px 0px"}}>
                       <Translate id="placeDetail.noImages" />
                    </Grid>
                )
            }
            gallery = place.gallery.map((photo) => {
                return {
                    original: photo.imageUrl,
                    thumbnail: photo.imageUrl,
                };
            });
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} lg={8}>
                    <ImageGallery items={gallery} height="300px" showFullscreenButton={false} lazyLoad additionalClass={classes.imageGallery} />
                </Grid>
            </Grid>
        );
    };

    const loadReviews = () => {
        //TODO
        return (
            <Grid container>
                <Grid item xs={12}>
                    reviews
                </Grid>
            </Grid>
        );
    };
    return (
        <>
            <Header />
            <Grid container justify="center">
                <Grid item xs={12} lg={8}>
                    <Paper style={{ marginBottom: "1em" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    className={classes.coverPhoto}
                                    style={{ backgroundImage: `url(${place.backgroundPhoto})` }}
                                    justify="center"
                                ></Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center" className={classes.avatarContainer}>
                                    <Grid item xs={4}>
                                        <Grid container justify="center">
                                            <Grid item xs={6}>
                                                <Avatar aria-label="recipe" className={classes.avatar} src={place.avatar}></Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center">
                                    <Grid item xs={4}>
                                        <Grid container>
                                            <Grid item xs={12} className={classes.placeName}>
                                                <Loc value={"place." + place.slug} />
                                            </Grid>
                                            {/* <Grid item xs={12} className={classes.placeAddress}>
                                                {place.location + " " + place.streetNr}
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={1} />
                                    <Grid item xs={12} md={7}>
                                        <Tabs
                                            value={tabIndex}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={handleChange}
                                            aria-label="disabled tabs example"
                                            centered
                                        >
                                            <Tab label={loc("placeDetail.introduction")} />
                                            <Tab label={loc("placeDetail.description")} />
                                            <Tab label={loc("placeDetail.photos")} />
                                            {/* <Tab label="Reviews" disabled /> */}
                                        </Tabs>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    {tabIndex === 0 && loadShortAbout()}
                    <Paper style={{ margin: "1em 0" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                {loadTabData()}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(withStyles(styles)(PlaceDetail));
