const initialState = {
    open: false,
    type: "success",
    message: ""
}
  
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_ALERT":
            const { open, type, message } = action.payload;
            return {
                ...state,
                open,
                type,
                message
            };
      default:
        return state
    }
}
  