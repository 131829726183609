import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CardMedia } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import background from "../theme/images/kereses.jpg";

const useStyles = makeStyles({
    root: {},
    media: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    avatar: {
        height: 60,
        width: 60
    },
    textContainer: {
        height: "100%"
    },
    title: {
        fontSize: "3vw",
        color: "#3B5441"
    },
    subtitle: {
        fontSize: "1.5vw"
    }
});

const ServiceCard = props => {
    const classes = useStyles();
    const { title, subtitle, textPosition } = props;

    const textContainer = () => {
        return (
            <Grid item xs={6}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                    className={classes.textContainer}
                >
                    <Grid item className={classes.title}>
                        {title}
                    </Grid>
                    <Grid item className={classes.subtitle}>
                        {subtitle}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const imageContainer = () => {
        return (
            <Grid item xs={6}>
                <CardMedia className={classes.media} image={background} title="Contemplative Reptile" />
            </Grid>
        );
    };

    if (textPosition === "right") {
        return (
            <Grid container direction="row" spacing={10}>
                {imageContainer()}
                {textContainer()}
            </Grid>
        );
    } else {
        return (
            <Grid container direction="row" spacing={10}>
                {textContainer()}
                {imageContainer()}
            </Grid>
        );
    }
};

export default withRouter(ServiceCard);
