import React, { useState } from "react";
import { Grid, CircularProgress, Typography, Button, TextField, FormHelperText } from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import classnames from "classnames";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import decode from "jwt-decode";
import { Translate, loc } from "components";
import withStyles from "@material-ui/core/styles/withStyles";

// import { FacebookAuth, GoogleAuth } from "components";

const styles = theme => ({
    greeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    errorMessage: {
        textAlign: "center",
    },
    successMessage: {
        textAlign: "center",
        color: "green",
    },
    textFieldUnderline: {
        "&:before": {
            borderBottomColor: theme.palette.primary.light,
        },
        "&:after": {
            borderBottomColor: theme.palette.primary.main,
        },
        "&:hover:before": {
            borderBottomColor: `${theme.palette.primary.light} !important`,
        },
    },
    textField: {
        borderBottomColor: theme.palette.background.light,
    },
    formButtons: {
        width: "100%",
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    forgetButton: {
        textTransform: "none",
        fontWeight: 400,
        backgroundColor: "transparent",
    },
    loginLoader: {
        marginLeft: theme.spacing(4),
    },
    formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint + "40",
    },
});

const Login = (props) => {

    const { classes } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState({ email: "", password: "" });
    const [loginValue, setLoginValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const login = () => {
        setIsLoading(true);
        axios
            .post("/login/", { email: loginValue, password: passwordValue })
            .then((res) => {
                Cookies.set("token", res.data.token, { expires: 7 });
                Cookies.set("dataToken", res.data.dataToken, { expires: 7 });
                
                dispatch({ type: "SIGN_IN_SUCCESS", payload: { isAuthenticated: true, user: decode(res.data.dataToken).user } });
                setIsLoading(false);

                history.push("/facility/sosfurdo");
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errorCode) {
                    let errorCode = error.response.data.errorCode;
                    switch (errorCode) {
                        case "auth/user-not-found":
                            setErrorMsg({ email: loc("error.login.userNotFound"), password: "" })
                            break;
                        case "auth/wrong-password":
                            setErrorMsg({ email: "", password: loc("error.login.wrongPassword") })
                            break;
                        case "auth/account-exists-with-different-credential":
                            setErrorMsg({ email: "", password: loc("error.login.accountExistWithDiffCred") })
                            break;
                        case "auth/too-many-requests":
                            setErrorMsg({ email: loc("error.logintooManyRequest"), password: "" })
                            break;
                        default:
                            setErrorMsg({ email: loc("error.login.loginError"), password: "" })
                            break;
                    }
                } else {
                    setErrorMsg({ email: loc("error.login.loginError"), password: "" });
                }       
                setIsLoading(false);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        login(loginValue, passwordValue);
    }

    return (
        <Translate>
            {({ translate }) => <>
                <form className={classes.root} onSubmit={handleSubmit}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <Typography variant="h1" className={classes.greeting}>
                                <Translate id="login.loginWelcome" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.subGreeting}>
                                <Translate id="login.loginSubtitle" />
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} style={{ minWidth: "100%" }}>
                            <FacebookAuth textButton="Login with facebook" btnStyle={classes.facebookBtn} />
                        </Grid>

                        <GoogleAuth textButton="Login with Google" btnStyle={classes.facebookBtn} />
                        <Grid item>
                            <Typography className={classes.formDividerWord}>vagy</Typography>
                        </Grid> */}
                    
                        <TextField
                            id="email"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={loginValue}
                            onChange={(e) => setLoginValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("login.email")}
                            type="email"
                            fullWidth 
                            required
                        />
                        <FormHelperText error>{errorMsg.email}</FormHelperText>
                        <TextField
                            id="password"
                            InputProps={{
                                classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                },
                            }}
                            value={passwordValue}
                            onChange={(e) => setPasswordValue(e.target.value)}
                            margin="normal"
                            placeholder={translate("login.password")}
                            type="password"
                            fullWidth
                        />
                        <FormHelperText error>{errorMsg.password}</FormHelperText>
                        <Grid container className={classes.formButtons}>
                            <Grid item>
                                {isLoading ? (
                                    <CircularProgress size={26} className={classes.loginLoader} />
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        <Translate id="login.loginBtn" />
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                <Button color="primary" size="large" className={classes.forgetButton} onClick={() => props.setActiveTabId(2)}>
                                    <Translate id="login.forgetPassword" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </>}
        </Translate>
    );
};

export default withRouter(withStyles(styles)(Login));
