import React from 'react';
import { withRouter, useLocation } from "react-router-dom";
import { PageNotFound, ResetPassword } from "components";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const FirebaseActions = () => {
    let query = useQuery();
    
    const mode = query.get("mode");
    const code = query.get("oobCode");
    switch(mode){
        case "resetPassword":
            return (
                <ResetPassword code={code}/>
            )
        // more firebase actions here(sign up confirmation,change email,)
        default:
            return(
                <PageNotFound />
            )
    }
}

export default withRouter(FirebaseActions)