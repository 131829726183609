import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

export const useSubFacilities = (facilityId, refresh) => {
    const [subFacilities, setSubFacilities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSubFacilities = async () => {
            if (facilityId) {
                await axios.get(`/subfacilities/${facilityId}`).then(async (res) => {
                    var subFacilitiesData = res.data;
                    var newSubValues = subFacilitiesData.map(item => {
                        item.startTime = moment(item.startTime, "HH:mm");
                        item.endTime = moment(item.endTime, "HH:mm");
                        item.adminStartTime = moment(item.adminStartTime, "HH:mm");
                        item.adminEndTime = moment(item.adminEndTime, "HH:mm");
                        return item;
                    });
                    setSubFacilities(newSubValues);
                    setIsLoading(false);
                });
            }
        };
        fetchSubFacilities();
    }, [facilityId, refresh]);

    return [subFacilities, isLoading];
};
