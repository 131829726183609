import React, { useState } from "react";
import { Map as LeafletMap, Marker, TileLayer } from "react-leaflet";
import { Grid } from "@material-ui/core";
import { Translate } from "components";

const Map = (props) => {
    const { mapClick, zoom, className, center, position } = props;

    const [showMap, setShowMap] = useState(false);

    const handleMapClick = (e) => {
        if (mapClick) {
            mapClick(e.latlng);
        }
    };

    if (showMap) {
        return (
            <LeafletMap 
                center={center ? center : [45.9442858, 25.0094303]} 
                zoom={zoom ? zoom : 6} 
                className={className} 
                onCLick={handleMapClick} 
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker key={"123"} position={position ? position : [45.9442858, 25.0094303]} />
            </LeafletMap>
        );
    } else {
        return (
            <Grid
                container
                alignItems="center"
                justify="center"
                className={className}
                onClick={() => setShowMap(true)}
                style={{ border: "1px solid #81c784", fontSize: 18, backgroundColor: "#F5F5F5", color: "#3B5441", borderRadius: 14 }}
            >
                <Translate id="map.showMap" />
            </Grid>
        );
    }
};

export default Map;
