import React, { useState } from "react";
import { Grid, CircularProgress, Typography, Button, TextField, FormHelperText } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Translate, loc } from "components";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    greeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    errorMessage: {
        textAlign: "center",
    },
    successMessage: {
        textAlign: "center",
        color: "green",
    },
    textFieldUnderline: {
        "&:before": {
            borderBottomColor: theme.palette.primary.light,
        },
        "&:after": {
            borderBottomColor: theme.palette.primary.main,
        },
        "&:hover:before": {
            borderBottomColor: `${theme.palette.primary.light} !important`,
        },
    },
    textField: {
        borderBottomColor: theme.palette.background.light,
    },
    formButtons: {
        width: "100%",
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    forgetButton: {
        textTransform: "none",
        fontWeight: 400,
        backgroundColor: "transparent",
    },
    loginLoader: {
        marginLeft: theme.spacing(4),
        marginTop: 32,
    },
    formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint + "40",
    },
    createAccountButton: {
        height: 46,
        textTransform: "none",
        marginTop: 32,
    },
});

const Login = (props) => {

    const { classes } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [isReSent, setIsReSent] = useState(false);
    const [errorMsg, setErrorMsg] = useState({email: ""});
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setIsReSent(isSent);
        axios
        .post("/forget-password/", { email: email })
        .then(() => {
            setIsLoading(false);
            setIsSent(true);
        }).catch(function(error) {
            setIsLoading(false);
            if (error.response && error.response.data && error.response.data.errorCode) {
                let errorCode = error.response.data.errorCode;
                switch (errorCode) {
                    case "auth/user-not-found":
                        setErrorMsg({ email: loc("error.emailNotExistInSystem")})
                        break;
                    default:
                        setErrorMsg({ email: loc("error.anErrorOccurredWhileTryingToSendYourMessage") });
                        break;
                }
            } else {
                setErrorMsg({ email: loc("error.anErrorOccurredWhileTryingToSendYourMessage") });
            }      
        });
    }

    if (isSent) {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Typography variant="h4" className={classes.greeting}>
                        <Translate id="forgetPassword.successTitle" />
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.subGreeting}>
                        <Translate id="forgetPassword.successSubtitle" />
                    </Typography>
                </Grid>    
                {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        className={classes.createAccountButton}
                        disabled={isReSent}
                        onClick={handleSubmit}
                    >
                        <Translate id="forgetPassword.successSendBtn" />
                    </Button>
                )}
            </Grid>
        )
    } else {
        return (
            <Translate>
                {({ translate }) => <>
                    <form className={classes.root} onSubmit={handleSubmit}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid item>
                                <Typography variant="h3" className={classes.greeting}>
                                    <Translate id="forgetPassword.title" />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" className={classes.subGreeting}>
                                    <Translate id="forgetPassword.subtitle" />
                                </Typography>
                            </Grid>            
                            <TextField
                                id="email"
                                InputProps={{
                                    classes: {
                                        underline: classes.textFieldUnderline,
                                        input: classes.textField,
                                    },
                                }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                margin="normal"
                                placeholder={translate("login.email")}
                                type="email"
                                fullWidth 
                                required
                                error={errorMsg.email ? true : false}
                            />
                            {errorMsg && errorMsg.email && <FormHelperText error >{errorMsg.email}</FormHelperText>}
                            {isLoading ? (
                                <CircularProgress size={26} className={classes.loginLoader} />
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    className={classes.createAccountButton}
                                >
                                    <Translate id="forgetPassword.sendBtn" />
                                </Button>
                            )}
                        </Grid>
                    </form>
                </>}
            </Translate>
        );
    }

    
};

export default withRouter(withStyles(styles)(Login));
