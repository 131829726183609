import React, { useState } from "react";
import { Grid, Avatar, Tabs, Tab, Paper, Typography, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter, useParams } from "react-router-dom";
import { Header, Map, Calendar, Translate, loc, CenterModal, Contact } from "components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactHtmlParser from "react-html-parser";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useFacility, useSubFacilities } from "actions";

const styles = (theme) => ({
    coverPhoto: {
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#000",
        paddingTop: "40%",
        width: "100%",
    },
    avatarContainer: {
        marginBottom: "1em",
        marginTop: "-8%",
    },
    avatar: {
        width: "100%",
        height: "100%",
        border: "5px solid white",
    },
    facilityName: {
        textAlign: "center",
        fontSize: 24,
        fontWeight: "bold",
        color: "#81c784",
    },
    facilityAddress: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: "bold",
        color: "#81c784",
    },
    facilityDescription: {
        textAlign: "justify",
        padding: 15,
    },
    facilityMap: {
        padding: 15,
    },
    mapSize: {
        width: "100%",
        height: "30vh",
        borderRadius: 14
    },
    imageGallery: {
        img: {
            maxHeight: 300,
        },
    },
    description: {
        fontSize: "12px",
        color: "#002539",
    },
    moreDescription: {
        color: "#81c784",
        fontWeight: "bold",
        textTransform: "none",
        backgroundColor: "rgba(74, 74, 74, 0.07)",
        borderRadius: 8,
        "&:hover": {
            backgroundColor: "rgba(74, 74, 74, 0.1)",
        },
    },
    calendarContainer: {
        margin: 30,
    },
    calendarMobileContainer: {
        margin: 10,
    }
});

const FacilityDetail = (props) => {
    const { classes } = props;

    let { slug } = useParams();

    const isMobile = useMediaQuery('(max-width:600px)');

    //TODO refreshapps ha szukseges frisitenio
    const [facility, isLoading] = useFacility(slug, 0);
    const [subFacilities] = useSubFacilities(facility.id, 0);
    const [tabIndex, setTabIndex] = useState(0);
    const [open, setOpen ] = React.useState(false);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const loadTabData = () => {
        switch (tabIndex) {
            case 0:
                return loadAppointments();
            case 1:
                return loadAbout();
            case 2:
                return loadPhotos();
            case 3:
                return loadReviews();
            default:
                break;
        }
    };

    const loadAppointments = () => {
        return (
            <>
                <Grid container>
                    <Grid item xs={12} className={isMobile ? classes.calendarMobileContainer : classes.calendarContainer}>
                        <Calendar facility={facility} subFacilities={subFacilities} />
                    </Grid>
                    <Grid container justify="center" style={{marginBottom: 10}}>
                        <Grid item>
                            <Button onClick={() => setOpen(true)} variant="outlined" color="primary">Útmutató</Button>
                        </Grid>
                    </Grid>
                    <CenterModal open={open} closeModal={() => setOpen(false)} title="Útmutató">
                        <Grid item xs={12} style={{ textAlign: "justify", fontSize: 16, marginTop: "1em" }}>
                            A zöld téglalapokban látható, hogy hány szabad hely van még az adott órában. Ezekre a téglalapokra kattintva tudunk foglalást
                            készíteni.
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "justify", fontSize: 16 }}>
                            A kék téglalapok az általunk leadott foglalásokat mutatják.
                        </Grid> 
                    </CenterModal>
                </Grid>
            </>
        );
    };

    const loadShortAbout = () => {
        const latLng = facility.latLng;
        return (
            <Paper>
                <Grid container>
                    <Grid item xs={12} lg={6} className={classes.facilityDescription}>
                        <Grid container direction="column" alignItems="center">
                            <Grid item xs={12}>
                                <Typography component={"div"} className={classes.description}>
                                    {ReactHtmlParser(facility.shortDescription)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" alignItems="flex-end">
                            <Grid item xs={6}>
                                <Button className={classes.moreDescription} onClick={() => setTabIndex(1)}>
                                    <Translate id="facilityDetail.knowMore" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.facilityMap}>
                        {latLng && (
                            <Map zoom={14} className={classes.mapSize} position={[latLng.lat, latLng.lng]} center={[latLng.lat, latLng.lng]} />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Contact facility={facility} />
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const loadAbout = () => {
        const latLng = facility.latLng;
        return (
            <Grid container>
                <Grid item xs={12} lg={6} className={classes.facilityDescription}>
                    <Typography component={"div"} className={classes.description}>
                        {ReactHtmlParser(facility.description)}
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6} className={classes.facilityMap}>
                    {latLng && <Map zoom={12} className={classes.mapSize} position={[latLng.lat, latLng.lng]} center={[latLng.lat, latLng.lng]} />}
                </Grid>
            </Grid>
        );
    };

    const loadPhotos = () => {
        var gallery = [];
        if (facility.gallery) {
            if (facility.gallery.length === 0) {
                return (
                    <Grid item xs={12} style={{ fontSize: "1.5em", textAlign: "center", padding: "30px 0px"}}>
                       <Translate id="facilityDetail.noImages" />
                    </Grid>
                )
            }
            gallery = facility.gallery.map((photo) => {
                return {
                    original: photo.imageUrl,
                    thumbnail: photo.imageUrl,
                };
            });
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} lg={8}>
                    <ImageGallery items={gallery} height="300px" showFullscreenButton={false} lazyLoad additionalClass={classes.imageGallery} />
                </Grid>
            </Grid>
        );
    };

    const loadReviews = () => {
        //TODO
        return (
            <Grid container>
                <Grid item xs={12}>
                    reviews
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Header />
            { !isLoading && 
            <Grid container justify="center">
                <Grid item xs={12} lg={8}>
                    <Paper style={{ marginBottom: "1em" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    className={classes.coverPhoto}
                                    style={{ backgroundImage: `url(${facility.backgroundPhoto})` }}
                                    justify="center"
                                ></Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center" className={classes.avatarContainer}>
                                    <Grid item xs={4}>
                                        <Grid container justify="center">
                                            <Grid item xs={6}>
                                                <Avatar aria-label="recipe" className={classes.avatar} src={facility.avatar}></Avatar>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center">
                                    <Grid item xs={4}>
                                        <Grid container>
                                            <Grid item xs={12} className={classes.facilityName}>
                                                {facility.name}
                                            </Grid>
                                            <Grid item xs={12} className={classes.facilityAddress}>
                                                <Translate id={facility.location}/> { " " + facility.streetNr}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={1} />
                                    <Grid item xs={12} md={7}>
                                        <Tabs
                                            value={tabIndex}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={handleChange}
                                            aria-label="disabled tabs example"
                                            centered
                                        >
                                            <Tab label={loc("facilityDetail.reservation")} />
                                            <Tab label={loc("facilityDetail.description")} />
                                            <Tab label={loc("facilityDetail.photos")} />
                                            {/* <Tab label="Reviews" disabled /> */}
                                        </Tabs>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    {tabIndex === 0 && loadShortAbout()}
                    <Paper style={{ margin: "1em 0" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                {loadTabData()}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>}
        </>
    );
};

export default withRouter(withStyles(styles)(FacilityDetail));
