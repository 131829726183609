import React, { useEffect, useState } from "react";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Grid,
    Paper,
    Modal,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { loc, Loc } from "components";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAlert }  from "actions";

const styles = (theme) => ({
    modalContainer: {
        position: "absolute",
        width: "400px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    facilityName: {
        textAlign: "center",
        fontSize: 16,
        fontWeight: "bold",
        color: theme.palette.primary.main,
    },
    facilityAddress: {
        textAlign: "center",
        fontSize: 12,
        fontWeight: "bold",
        color: theme.palette.primary.light,
    },
    modalTitle: {
        fontSize: "22px",
        textAlign: "center",
        fontWeight: "bold",
        color: theme.palette.primary.light,
        // marginBottom: "1rem",
    },
    errorMessage: {
        color: "red",
        textAlign: "center",
    },
    modalHeader: {
        //backgroundColor: "#dddddd",
        borderBottom: "1px solid #ddd",
        paddingBottom: "1em",
    },
});

const calcDiffTime = (startTime, endTime) => {
    var start = moment.utc(startTime, "HH:mm");
    var end = moment.utc(endTime, "HH:mm");
    if (end.isBefore(start)) return null;
    var duration = moment.duration(end.diff(start));
    var s = moment.utc(+duration);
    return { time: s, duration: duration };
};

const AddWeekAppointment = (props) => {
    const { classes, facility, closeModal, dateNr, appointment, open } = props;

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);

    const [appointmentId, setAppointmentId] = React.useState(null);
    const [startTime, setStartTime] = React.useState(moment());
    const [isExternalUser, setIsExternalUser] = useState(false);
    const [externalUserName, setExternalUserName] = useState("");
    const [externalPhone, setExternalPhone] = useState("");

    useEffect(() => {

        const openModal = () => {
            if (appointment) {
                setAppointmentId(appointment.id);
                setStartTime(appointment.startTime);
                setIsExternalUser(appointment.isExternalUser);
                if (appointment.isExternalUser) {
                    setExternalUserName(appointment.externalUserName);
                    setExternalPhone(appointment.externalPhone);
                }
            } else {
                setAppointmentId(undefined);
                setStartTime(dateNr);
            }
        };

        if (open) {
            openModal();
        }
    }, [open, appointment, dateNr]);

    const handleExteralUser = (e) => {
        const checked = e.target.checked;
        setIsExternalUser(e.target.checked);
        if (!checked) {
            setExternalUserName(undefined);
            setExternalPhone(undefined);
        }
    };

    const saveDayAppointment = () => {
        var newStartTime = moment(startTime.format("YYYY-MM-DD ") + facility.startTime.format("hh:mm"));
        var newEndTime = moment(startTime.format("YYYY-MM-DD ") + facility.endTime.format("hh:mm"));
        var diff = calcDiffTime(facility.startTime, facility.endTime);
        var appointment = {
            startTime: newStartTime,
            endTime: newEndTime,
            duration: diff.duration.asMinutes(),
            diffTime: diff.time,
            price: 500,
            userId: isExternalUser ? undefined : user.id,
            userName: isExternalUser ? undefined : user.name,
            facilityId: facility.id,
            paid: false,
            paidDate: null,
            places: facility.places,
            isExternalUser,
            externalPhone,
            externalUserName,
            isDayAppointment: true,
        };
        axios.post("/appointment/", { appointment, id: appointmentId ? appointmentId : 0 }).then(() => {
            closeModal();
            axios.post("/email-to-user", { type: "createAppointment", appointment: appointment, facility: facility });
            // setFacility(facility.data);
            //TODO egyszerusodik ha atirod a fentit
            setIsExternalUser(false);
            setExternalPhone("");
            setExternalUserName("");
            // setIsLocal(false);
            // setPlaces(1);
            dispatch(setAlert(true, "success", loc("addDayAppointment.savedSuccessfully")));
        });
    };

    const deleteAppointment = () => {
        axios.post("/appointmentDelete/", { id: appointmentId }).then((e) => {
            closeModal();
            axios.post("/email-to-user", { type: "deleteAppointment", appointment: appointment, facility: facility });
            dispatch(setAlert(true, "success", loc("addDayAppointment.deletedSuccessfully")));
        });
    };

    return (
        <Modal open={open} onClose={closeModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
            <Paper>
                <Grid container className={classes.modalContainer}>
                    <Grid item xs={12}>
                        <Grid container className={classes.modalHeader}>
                            <Grid item xs={12} className={classes.facilityName}>
                                {facility.name}
                            </Grid>
                            <Grid item xs={12} className={classes.facilityAddress}>
                                {facility.location}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} className={classes.modalTitle}>
                                        <Loc value="addMonthAppointment.allDayReservation" />
                                    </Grid>
                                    <Grid item xs={12} className={classes.modalDate}>
                                        <Loc value="addMonthAppointment.nextDate" date={startTime.format("YYYY-MM-DD")} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.modalDate}>
                                        <Loc value="addMonthAppointment.price" /> { facility.dayPrice + " lej"}
                                    </Grid>
                                    {user.business && (
                                        <Grid container justify="center">
                                            <Grid item className={classes.externalUser}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isExternalUser}
                                                            onChange={handleExteralUser}
                                                            name="isExternalUser"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={loc("addMonthAppointment.externalUser")}
                                                />
                                            </Grid>
                                            {isExternalUser && (
                                                <Grid item className={classes.externalUser} xs={12}>
                                                    <TextField
                                                        id="externalUserName"
                                                        value={externalUserName}
                                                        onChange={(e) => setExternalUserName(e.target.value)}
                                                        margin="normal"
                                                        label={loc("addMonthAppointment.userName")}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                            {isExternalUser && (
                                                <Grid item className={classes.externalUser} xs={12}>
                                                    <TextField
                                                        id="externalUserPhone"
                                                        value={externalPhone}
                                                        onChange={(e) => setExternalPhone(e.target.value)}
                                                        margin="normal"
                                                        label={loc("addMonthAppointment.userPhoneNr")}
                                                        fullWidth
                                                        type="phone"
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <Button variant="outlined" color="primary" onClick={closeModal}>
                                            <Loc value="general.cancel" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" color="primary" onClick={deleteAppointment}>
                                            <Loc value="general.delete" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" color="primary" onClick={saveDayAppointment}>
                                            <Loc value="general.save" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    );
};

export default withStyles(styles)(AddWeekAppointment);
