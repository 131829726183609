import React from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Header } from "components";
import { Translate } from "components";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => {

};

const Profile = () => {

    return (
        <>
            <Header />
            <Grid container  justify="center">
                <Grid item style={{fontSize: 40, marginTop: "5vw"}}>
                    <Translate id="comingSoon" />
                    {/* <Translate>
                        {({ translate }) => 
                            <Card>
                                <TextField
                                    id="email"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={user.email}
                                    // onChange={(e) => setLoginValue(e.target.value)}
                                    margin="normal"
                                    placeholder={translate("login.email")}
                                    type="email"
                                    fullWidth
                                />
                            </Card> 
                        }
                    </Translate> */}
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(withStyles(styles)(Profile));
